// import axios from 'axios'
import Vue from 'vue';
import { HTTP } from '@/api/http_utils'
// import axios from 'axios'
// import router from '@/router/index.js'


export default {
    namespaced: true,
    state: {
        Profile: {}
    },
    getters: {
        Profile: state => state.Profile,
        profileId: state => state.Profile.id,
        userName: state => state.Profile,
    },
    mutations: {
        SET_PROFILE (state, data) {
            localStorage.setItem('profile', JSON.stringify(data))
            state.Profile = data
        },
        REMOVE_PROFILE (state) {
            state.Profile = {}
            localStorage.removeItem('profile');
        }
    },
    actions: {
        get ({ commit }) {
            return new Promise((resolve, reject) => {
                if (this.state.Profile) {
                    resolve(this.state.Profile)
                } else if (localStorage.getItem('profile')) {
                    commit('SET_PROFILE', JSON.parse(localStorage.getItem('profile')))
                    resolve(JSON.parse(localStorage.getItem('profile')))
                } else {
                    HTTP.get('/profile' ).then((response) => {
                        if (response) {
                            commit('SET_PROFILE', response)
                        }
                        resolve(response)
                    }).catch(error => {
                        reject(error)
                    })
                }
            })
        },
        update ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.patch('/profile', data).then((response) => {
                    commit('SET_PROFILE', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOrders ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/profile/orders' ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };