<template>
    <div>
        <modal-component
        :modalId="'SignInModal'"
        :modalDialogClass="'modal-dialog-centered'"
        :modalBodyClass="'tab-content py-4'"
        @closeModal="closeModal"
        >
        <template slot="header">
            <ul class="nav nav-tabs card-header-tabs" role="tablist">
                <li class="nav-item"><a class="nav-link active" href="#signin-tab1" data-toggle="tab" role="tab" aria-selected="true"><i class="czi-unlocked mr-2 mt-n1"></i>{{ $t('Sign in') }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#signup-tab1" data-toggle="tab" role="tab" aria-selected="false"><i class="czi-user mr-2 mt-n1"></i>{{ $t('Sign up') }}</a></li>
            </ul>
            <button class="close" id="closeModal" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </template>
        <template slot="body">
            <div class="tab-pane fade show active" id="signin-tab1">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(loginSubmit)" class="needs-validation show active was-validated" autocomplete="off" novalidate >
                <div class="form-group">
                  <label for="si-email">{{ $t('Email address') }}</label>
                  <ValidationProvider vid="username" name="email" rules="required|email" v-slot="{ errors }">
                    <input class="form-control" v-model="login.username" aria-describedby="emailHelp" type="email" id="si-email" autocomplete="true" placeholder="johndoe@example.com" required>
                    <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="si-password">{{ $t('Password') }}</label>
                  <div class="password-toggle">
                    <ValidationProvider vid="password" name="email" rules="required" v-slot="{  }">
                      <input class="form-control" v-model="login.password" :type="loginPasswordType" id="si-password" required>
                      <label class="password-toggle-btn">
                        <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator" @click="changePasswordType('loginPasswordType')"></i><span class="sr-only">Show password</span>
                      </label>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group d-flex flex-wrap justify-content-between">
                  <div class="custom-control custom-checkbox mb-2">
                    <input class="custom-control-input" type="checkbox" id="si-remember">
                    <label class="custom-control-label" for="si-remember">{{ $t('Remember me') }}</label>
                  </div>
                  <router-link :to="{ name: 'PasswordRecovery' }" class="font-size-sm">
                  <span v-on:click="closeModal()">{{ $t('Forgot password?') }}</span>
                  </router-link>
                </div>
                <button class="btn btn-primary btn-block btn-shadow" type="submit">{{ $t('Sign in') }}</button>
              </form>
            </ValidationObserver>
            </div>
            <div class="tab-pane fade" id="signup-tab1">
              <ValidationObserver ref="formSignUp" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(signUp)" class="needs-validation" autocomplete="off" novalidate >
                  <div class="form-group">
                    <p v-if="captchaError">
                      <span class="invalid-feedback-show text-danger">{{ $t('Bad recaptcha') }}</span>
                    </p>
                    <label for="su-name">{{ $t('First name') }}</label>
                    <ValidationProvider vid="first_name" name="first_name" rules="required" v-slot="{ errors }">
                      <input class="form-control" type="text" id="su-name" v-model="register.first_name" :placeholder="$t('Petr')" required>
                      <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="su-name">{{ $t('Last name') }}</label>
                    <ValidationProvider vid="last_name" name="last_name" rules="required" v-slot="{ errors }">
                      <input class="form-control" type="text" id="su-name" v-model="register.last_name" :placeholder="$t('Petrov')" required>
                      <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="su-email">{{ $t('Email address') }}</label>
                    <ValidationProvider vid="email" name="email" rules="required|email" v-slot="{ errors }">
                      <input class="form-control" type="email" id="su-name" v-model="register.email" placeholder="test@mail.com" required>
                      <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="su-password">{{ $t('Password') }}</label>
                    <div class="password-toggle">
                      <ValidationProvider vid="password" name="password" rules="required|min:6" v-slot="{ errors }">
                        <input class="form-control" v-model='register.password' :type="registerPasswordType" id="su-password" required>
                        <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                      </ValidationProvider>
                      <label class="password-toggle-btn">
                        <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator" @click="changePasswordType('registerPasswordType')"></i><span class="sr-only">Show password</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="su-password-confirm">{{ $t('Confirm password') }}</label>
                    <div class="password-toggle">
                      <ValidationProvider vid="password_confirmation" name="password_confirmation" rules="required|min:6" v-slot="{ errors }">
                        <input class="form-control" v-model='register.password_confirmation' :type="registerConfirmPasswordType" id="su-password-confirm" required>
                        <div class="invalid-feedback" style="display: block !important" v-bind:style="{ 'display: block !important': errors.length > 0 }">{{ errors[0] }}</div>
                      </ValidationProvider>
                      <label class="password-toggle-btn">
                        <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator" @click="changePasswordType('registerConfirmPasswordType')"></i><span class="sr-only">Show password</span>
                      </label>
                    </div>
                  </div>
                  <button class="btn btn-primary btn-block btn-shadow" type="submit">{{ $t('Sign up') }}</button>
                </form>
              </ValidationObserver>
            </div>
        </template>
        </modal-component>
    </div>
</template>
<script>
import ModalComponent from '@/components/ModalComponent'
import mixinApiErrors from '@/mixins/mixinApiErrors'

export default {
    name: 'SignInModal',
    mixins: [mixinApiErrors],
    components: {
      ModalComponent
    },
    data () {
      return {
        login: {
          username: null,
          password: null,
          recaptcha: null
        },
        register: {
          first_name: null,
          last_name: null,
          email: null,
          password: null,
          password_confirmation: null,
          recaptcha: null
        },
        captchaError: false,
        loginPasswordType: 'password',
        registerPasswordType: 'password',
        registerConfirmPasswordType: 'password'
      }
    },
    methods: {
      changePasswordType (passType) {
        switch (passType) {
        case 'loginPasswordType':
          if (this.loginPasswordType === 'password') {
            this.loginPasswordType = 'text'
          } else {
            this.loginPasswordType = 'password'
          }
          break
        case 'registerPasswordType':
          if (this.registerPasswordType === 'password') {
            this.registerPasswordType = 'text'
          } else {
            this.registerPasswordType = 'password'
          }
          break
        case 'registerConfirmPasswordType':
          if (this.registerConfirmPasswordType === 'password') {
            this.registerConfirmPasswordType = 'text'
          } else {
            this.registerConfirmPasswordType = 'password'
          }
          break
        }
      },
      async signUp () {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('verify')
        this.register['g-recaptcha-response'] = token
        this.$store.dispatch('auth/registerUser', this.register).then(() => {
          this.$store.dispatch('account/get')
          this.closeModal()
        }).catch(() => {
          if (this.$apiErrors) {
            if ('g-recaptcha-response' in this.$apiErrors) {
              delete this.$apiErrors['g-recaptcha-response']
              this.captchaError = true
            } else {
              this.captchaError = false
            }
            this.$refs.formSignUp.setErrors(this.$apiErrors)
          }
        })
      },
      closeModal: function () {
        document.getElementById('closeModal').click()
        this.$parent.$emit('closeSignInModal')
      },
      loginSubmit() {
        // await this.$recaptchaLoaded()
        // const token = await this.$recaptcha('login')
        this.$store.dispatch('auth/authUser', { username: this.login.username, password: this.login.password, grant_type: 'password' }).then(() => {
          // document.getElementById('closeSignInModal').click()
          // document.getElementById('closeModal').click()
          this.$store.dispatch('account/get')
          this.closeModal()
        }).catch(() => {
          this.$refs.form.setErrors({
            username: ['Email or password has bean incorrect']
          });
        })
      },
    }
}
</script>