<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- Sign in / sign up modal-->
    <!-- <SignInOut /> -->
    <sign-in-modal v-if="showSignIn"></sign-in-modal>
    <!-- Quick View Modal-->
    <!-- <QuickViewModal /> -->
    <!-- Navbar 3 Level (Light)-->
    <Header />
    <!-- Page Title (Shop)-->
    <!-- <PageTitle /> -->
    <!-- Page Content-->
      <!-- $route.fullPath -->
    <transition name="fade" mode="out-in" v-on:after-leave="afterLeave">
      <keep-alive :max="25">
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
      </keep-alive>
        <!-- <router-view :key="$route.path ? $route.path : $route.fullPath"></router-view> -->
    </transition>
    <transition name="fade" mode="out-in" v-on:after-leave="afterLeave">
      <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
    </transition>

    <!-- Toast: Added to Cart-->
    <div class="toast-container toast-bottom-center">
      <div class="toast mb-3" id="cart-toast" data-delay="5000" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header bg-success text-white"><i class="czi-check-circle mr-2"></i>
          <h6 class="font-size-sm text-white mb-0 mr-auto">Added to cart!</h6>
          <button class="close text-white ml-2 mb-1" type="button" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="toast-body">This item has been added to your cart.</div>
      </div>
    </div>
    <!-- Footer-->
    <Footer />
    <!-- Toolbar for handheld devices-->
    <Toolbar />
    <!-- Back To Top Button-->
    <a class="btn-scroll-top" @click="smoothScroll()" v-bind:class="{ 'show': showToTop }"  href="#top" data-scroll><span class="btn-scroll-top-tooltip text-muted font-size-sm mr-2"></span><i class="btn-scroll-top-icon czi-arrow-up">   </i></a>
    <!-- JavaScript libraries, plugins and custom scripts-->
  </div>
</template>

<script>
import '@/plugins/jquery'
import 'bootstrap'
import 'popper.js'

import { EventBus } from '@/plugins/event-bus.js';
import SignInModal from '@/components/SignInModal'
// import AddressModal from '@/components/Account/AddressModal'
// import SignInOut from '@/components/SignInOut'
// import QuickViewModal from '@/components/QuickViewModal'
import Header from '@/components/Header'
// import PageTitle from '@/components/PageTitle'
import Footer from '@/components/Footer'
import Toolbar from '@/components/Toolbar'
// import { mapGetters } from 'vuex'

import SmoothScroll from 'smooth-scroll'

export default {
  name: 'App',
  components: {
    // QuickViewModal,
    Header,
    Footer,
    Toolbar,
    SignInModal,
    // AddressModal
  },
  data () {
    return {
      fullscreenLoading: false,
      openLoader: false,
      showSignIn: false,
      showAddressModal: false,
      showToTop: false
    }
  },
  computed: {
    // ...mapGetters({
    // isAuth: 'auth/isAuthenticated'
    // })
    // openModal () {
    //   return this.$store.state.modal === true
    // },
  },
  beforeMount () {
      window.addEventListener('scroll', this.handleScroll);
  },
  // get (tt, tt1) {
  //   console.log(tt, tt1)
  // },
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll);
  },
  mounted () {
    this.$on('closeSignInModal', function () {
      // console.log('closeSignInModal')
      this.showSignIn = false
    })

    this.$on('openSignInModal', function () {
      // console.log('openSignInModal')
      this.showSignIn = true
    })

    // this.$on('closeAddressModal', function () {
    //   // console.log('closeSignInModal')
    //   this.showAddressModal = false
    // })

    // this.$on('openAddressModal', function () {
    //   console.log('openAddressModal')
    //   this.showAddressModal = true
    // })

    // let src = 'http://127.0.0.1:8080/js/vendor.min.js'
    // if(document.querySelector("script[src='" + src + "']")){ return; }
    // let script = document.createElement('script');
    // script.setAttribute('src', src);
    // script.setAttribute('type', 'text/javascript');
    // script.setAttribute('defer', true);
    // document.body.appendChild(script)

    // let src2 = 'http://127.0.0.1:8080/js/theme.min.js'
    // if(document.querySelector("script[src='" + src2 + "']")){ return; }
    // let script2 = document.createElement('script');
    // script2.setAttribute('src', src2);
    // script2.setAttribute('type', 'text/javascript');
    // // script2.setAttribute('defer', true);
    // document.body.appendChild(script2)

    // let vendorScript = document.createElement('script')
    // vendorScript.setAttribute('src', '../public/js/vendor.min.js')
    // document.body.appendChild(vendorScript)
    // vendorScript.defer = true

    // let themeMainScript = document.createElement('script')
    // themeMainScript.setAttribute('src', '../public/js/theme.min.js')
    // document.body.appendChild(themeMainScript)
    // themeMainScript.defer = true
  },
  created () {
    EventBus.$on('loader', this.loaderData)
    // EventBus.$on('openAddressModal', this.openAddressModal)
    EventBus.$on('closeAddressModal', this.closeAddressModal)
    // EventBus.$on('addToCart', this.addToCart)
    this.getSettings()
    this.getCatalogs()
    this.getCart()
    // this.getCountries()
    // this.getComparison()
    this.$store.dispatch('comparison/get')
    if (this.isAuth) {
      this.$store.dispatch('account/get')
      this.$store.dispatch('wishlist/getList')
      this.$store.dispatch('order/getList')
      // console.log('get profile')
    }
  },
  watch: {
    openLoader: function (val) {
      // if (val === false) {
      //   setTimeout(() => {
      //     this.fullscreenLoading = val;
      //   }, 300);
      // } else {
      //   this.fullscreenLoading = val;
      // }
      this.fullscreenLoading = val;
      // this.fullName = val + ' ' + this.lastName
    },
  },
  methods: {
    // addToCart (product, quantity) {
    //   console.log('ttt')
    //   console.log(product, quantity)
    //   this.$store.dispatch('cart/add', [product, quantity]).then(() => {
    //         this.$message({
    //             showClose: true,
    //             message:  this.$i18n.t('Product added to cart.'),
    //             type: 'success',
    //             duration: 1000
    //         })
    //     }).catch(() => {})
    // },
    smoothScroll () {
      new SmoothScroll("[data-scroll]", {
          speed: 800,
          speedAsDuration: !0,
          offset: 40,
          header: "[data-scroll-header]",
          updateURL: !1
      })
    },
    handleScroll () {
      if (window.scrollY > 600) {
        this.showToTop = true
      } else {
        this.showToTop = false
      }
    },
    afterLeave () {
      this.$root.$emit('triggerScroll')
    },
    loaderData (val) {
      this.openLoader = val
    },
    // openAddressModal (addr) {
    //   // console.log(addr)
    //   this.addr = addr
    //   this.showAddressModal = true
    // },
    closeAddressModal () {
      this.showAddressModal = false
    },
    getCountries () {
      this.$store.dispatch('getCountries').then((response) => {
        this.countries = response
        }).catch(() => {
      })
    },
    getCatalogs () {
      this.$store.dispatch('catalog/getCatalogs').then((response) => {
        this.catalogs = response
        // setTimeout(() => {
        //   this.dropDownMenu()
        // }, 1000);
        }).catch(() => {
      })
    },
    getSettings () {
      this.$store.dispatch('getSettings').then((response) => {})
    },
    getCart () {
      this.$store.dispatch('cart/get')
    },
    closeModal: function () {
      this.$store.state.modal = false
    },
  }
}
</script>
<style lang="scss">
@import url('../public/css/vendor.min.css');
@import url('../public/css/theme.min.css');

.bg-secondary {
  /* background-color: #f3f5f9 !important; */
  background-color: #f2f4f6 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(206, 212, 226) !important;
}

.bg-dark {
  background-color: #333333 !important;
}

.bg-darker {
  background-color: #262626 !important;
}

.hr-dark {
  border-color: rgba(15, 1, 1, 0.12);
}

.el-tooltip__popper {
    // position: absolute;
    // border-radius: 4px;
    // padding: 10px;
    // z-index: 2000;
    // font-size: 12px;
    line-height: 1.5 !important;
    min-width: 10px;
    word-wrap: break-word;
}

.el-tooltip__popper.is-dark {
  display: block;
  position: absolute;
  // top: -.5rem;
  // left: 50%;
  // transform: translateX(-50%);
  transition: opacity .2s ease-in-out, top .2s ease-in-out;
  padding: .0625rem .375rem;
  border-radius: .1875rem;
  background-color: #4b566b !important;
  background: #4b566b !important;
  color: #fff;
  font-size: .6875rem;
  white-space: nowrap;
  cursor: pointer;
  // opacity: 0;
}
.el-tooltip__popper .popper__arrow {
    border-width: 6px;
    // background-color: #4b566b !important;
    // background: #4b566b !important;
}

.product-card > .btn-wishlist.active {
  color: #af2e2e !important;
}

</style>
