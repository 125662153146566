export default {
    data () {
      return {

      }
    },
    // computed: {
    //   getApiErrors () {
    //     return this.$store.getters['apiErrors']
    //   }
    // },
    watch: {
        getApiErrors: function () {
          // console.log(this.$store.getters['apiErrors'])
          this.$apiErrors = {}
            try {
                if (Object.keys(this.getApiErrors).length > 0) {
                    Object.keys(this.getApiErrors.errors).forEach(key => {
                    this.$apiErrors[key] = this.getApiErrors.errors[key]
                })
            }
            } catch (e) {
                //
                // console.log(e)
            }
        }
    }
}
