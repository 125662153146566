// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP, axiosBasic } from '@/api/http_utils'
import axios from 'axios'
import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        getList ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/blog-posts').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getByHumabUrl ({ commit }, humanUrl) {
            return new Promise((resolve, reject) => {
                HTTP.get('/blog-posts/human_url/' + humanUrl).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // getRecently ({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get(`/blog-posts/recently` ).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // getProductReveiws ({ commit }, Id) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get(`/blog-posts/${Id}/reviews` ).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // }
    },
  };