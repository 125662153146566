import Vue from 'vue'
import Vuex from 'vuex'
import language from './modules/language'
import auth from './modules/auth'
import customerService from './modules/customerService'
import catalog from './modules/catalog'
import category from './modules/category'
import account from './modules/account'
import product from './modules/product'
import service from './modules/service'
import wishlist from './modules/wishlist'
import address from './modules/address'
import checkout from './modules/checkout'
import cart from './modules/cart'
import comparison from './modules/comparison'
import order from './modules/order'
import blog from './modules/blog'
import collection from './modules/collection'
import slider from './modules/slider'
import filter from './modules/filter'
import promotion from './modules/promotion'
import { HTTP } from '@/api/http_utils'
// import createWebSocketPlugin from '@/store/plugins/web_socket'
import createPersistedState from "vuex-persistedstate";
// import io from 'socket.io-client'
// import * as Cookies from 'js-cookie'

Vue.use(Vuex)

const storeModerator = (store, router) => {
  // listen to mutations
  store.subscribe(({ type, payload }, state) => {
    // console.log(type)
    if (type === 'auth/authUser') {
        store.dispatch('wishlist/getList')
        store.dispatch('account/get')
    }
  })
};

// const storeModerator = (store, router) => {
//   // listen to mutations
//   store.subscribe(({ type, payload }, state) => {
//     console.log(type)
//       // if commit('setAccessToken') was called dispatch 'getAuthUser'
//       if (type === 'cart/ADD_TO_CART') {
//           store.dispatch('cart/getCart');
//       }
//   });

//   store.subscribeAction(({ type, payload }, state) => {
//     console.log('subscribeAction', type)
//     switch (type) {
//       case 'cart/ADD_TO_CART': return router.push('cart/clear')
//     }
//   })

// };

// const plugin = createWebSocketPlugin(SocketIO)

export default new Vuex.Store({
  strict: true,
  state: {
    apiErrors: {},
    socialsList: {},
    countriesList: [],
    brandsList: [],
    settings: [],
    modal: false
  },
  getters: {
    apiErrors: state => state.apiErrors,
    socialsList: state => state.socialsList,
    countriesList: state => state.countriesList,
    modal: state => state.modal,
    brandsList: state => state.brandsList,
    settings: state => state.settings
  },
  mutations: {
    API_ERRORS (state, error) {
      state.apiErrors = {}
      state.apiErrors = error
    },
    SET_COUNTRIES (state, countries) {
      state.countriesList = countries
    },
    SET_BRANDS (state, brands) {
      state.brandsList = brands
    },
    SET_SOCIALS (state, socials) {
      state.socialsList = socials
    },
    SET_SETTINGS (state, settings) {
      state.settings = settings
    }
  },
  actions: {
    getErrors ({ commit }, apiErrors) {
      commit('API_ERRORS', apiErrors)
    },
    getPage ({ commit }, page) {
      return new Promise((resolve, reject) => {
          HTTP.get('/static-pages/' + page).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getFaqCategories ({ commit }) {
      return new Promise((resolve, reject) => {
          HTTP.get('/faqs-categories').then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getFaqCategory ({ commit }, Id) {
      return new Promise((resolve, reject) => {
          HTTP.get('/faqs-categories/' + Id).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    addFaqRequest ({ commit }, data) {
      return new Promise((resolve, reject) => {
          HTTP.post('/faqs-requests', data).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getCountries ({ commit }) {
      return new Promise((resolve, reject) => {
        var vuexData = JSON.parse(localStorage.getItem('vuex')) ?? {}
        if ('countriesList' in vuexData) {
          commit('SET_COUNTRIES', vuexData['countriesList'])
          resolve(vuexData['countriesList'])
        } else {
          HTTP.get('/countries').then((response) => {
            commit('SET_COUNTRIES', response)
            resolve(response)
          }).catch(error => {
              reject(error)
          })
        }
      })
    },
    getBrands ({ commit }) {
      return new Promise((resolve, reject) => {
          HTTP.get('/brands').then((response) => {
            commit('SET_BRANDS', response)
            resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getSocial ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('/socials').then((response) => {
          commit('SET_SOCIALS', response)
          resolve(response)
        }).catch(error => {
            reject(error)
        })
      })
    },
    getSettings ({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get('/settings').then((response) => {
          commit('SET_SETTINGS', response)
          resolve(response)
        }).catch(error => {
            reject(error)
        })
      })
    },
    getContacts ({ commit }) {
      return new Promise((resolve, reject) => {
          HTTP.get('/contacts').then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getOurStores ({ commit }) {
      return new Promise((resolve, reject) => {
          HTTP.get('/our_stores').then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    recoveryPassword ({ commit }, data) {
      return new Promise((resolve, reject) => {
          HTTP.post('/password/recovery', data).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    createServiceRequest ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          HTTP.post('/service-requests', payload).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    getShopFilters ({ commit }, data) {
      return new Promise((resolve, reject) => {
          HTTP.get('/shop-filters' + data).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    checkEmailExist ({ commit }, data) {
      return new Promise((resolve, reject) => {
          HTTP.post('/check-email', data).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    npGetCity ({ commit }, city) {
      return new Promise((resolve, reject) => {
          HTTP.get(`/get-cities?city=${city}`).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    },
    npGetWarehouses ({ commit }, data) {
      return new Promise((resolve, reject) => {
          HTTP.get(`/get-warehouses?city_ref=${data['city_ref']}&language=${data['language']}`).then((response) => {
              resolve(response)
          }).catch(error => {
              reject(error)
          })
      })
    }
  },
  modules: {
    auth,
    language,
    customerService,
    catalog,
    category,
    account,
    product,
    wishlist,
    cart,
    service,
    address,
    checkout,
    comparison,
    order,
    blog,
    collection,
    slider,
    promotion,
    filter
  },
  // plugins: [createPersistedState({
  //   storage: window.localStorage,
  // })],
  plugins: [
    createPersistedState(),
    storeModerator
    // storeModerator
  ],
})
