import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer, Cache } from 'axios-extensions'
import index from '../store/index'
// import Vue from 'vue'
import { EventBus } from '../plugins/event-bus.js';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials = true

export let CacheLoc = new Cache({ ttl: 1000 * 60 * 60 * 24, max: 1000 })

export let axiosCachedImage = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: true, defaultCache: CacheLoc }), { threshold: 2 * 1000 }),
  responseType: 'arraybuffer'
})

export let axiosBasic = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

let apiKey = localStorage.getItem('api_key')
let tokenType = localStorage.getItem('token_type')
if (apiKey !== undefined && tokenType !== undefined) {
  axiosBasic.defaults.headers.common['Authorization'] = tokenType + ' ' + apiKey
  axiosCachedImage.defaults.headers.common['Authorization'] = tokenType + ' ' + apiKey
}

/**
 *  Блок для предварительной обработки запросов/ответов
*/

axiosCachedImage.interceptors.request.use(function (config) {
  return setStartTime(config)
}, function (error) {
  return Promise.reject(error)
})

axiosCachedImage.interceptors.response.use((response) => {
  response = setEndTime(response)
  // if (response.duration > parseInt(process.env['VUE_APP_LOG_RESPONSE_DELAY']) && response.config.url !== process.env['VUE_APP_ROOT_API'] + 'logs') {
  //   sendLogData(response, 'http_slow_query')
  // }
  return response
}, function (error) {
  if (error.response.status >= parseInt(process.env['VUE_APP_LOG_LEVEL']) && error.response.config.url !== process.env['VUE_APP_ROOT_API'] + 'logs') {
    error.response = setEndTime(error.response)
    // sendLogData(error.response, 'http_bad_request')
    index.dispatch('getErrors', {}).then()
    index.dispatch('getErrors', error.response.data).then()
  }
  return Promise.reject(error)
})

axiosBasic.interceptors.request.use(function (config) {
  return setStartTime(config)
}, function (error) {
  return Promise.reject(error)
})

axiosBasic.interceptors.response.use((response) => {
  response = setEndTime(response)
  // if (response.duration > parseInt(process.env['VUE_APP_LOG_RESPONSE_DELAY']) && response.config.url !== process.env['VUE_APP_ROOT_API'] + 'logs') {
  //   sendLogData(response, 'http_slow_query')
  // }
  return response
}, function (error) {
  if (error.response.status >= parseInt(process.env['VUE_APP_LOG_LEVEL']) && error.response.config.url !== process.env['VUE_APP_ROOT_API'] + 'logs') {
    error.response = setEndTime(error.response)
    // sendLogData(error.response, 'http_bad_request')
    // index.dispatch('getErrors', {}).then()
    // index.dispatch('getErrors', error.response.data).then()
  }
  if (error.response.status === 401) {
    this.$store.dispatch('auth/logOut')
    // index.dispatch('getErrors', {}).then()
    // index.dispatch('getErrors', error.response.data).then()
    // index.dispatch('logout').then()
  }
  if (error.response.status === 400) {
    // index.dispatch('getErrors', {}).then()
    // index.dispatch('getErrors', error.response.data).then()
  }
  // toDo если обрабатывать 404, нужно написать исключение для подтверждения приглашения в компанию
  // if (error.response.status === 404) {
  //   index.dispatch('getErrors', {}).then()
  //   index.dispatch('getErrors', error.response.data).then()
  // }
  return Promise.reject(error)
})

/**
 * Добавляем в request время отправки
 */
function setStartTime (config) {
  config.metadata = { startTime: new Date() }
  return config
}

/**
 * Добавляем в response время получения и рассчитываем продолжительность запроса
*/
function setEndTime (response) {
  response.config.metadata.endTime = new Date()
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime
  return response
}

/**
 * Логирование медленных запросов и ошибок
 */
// function sendLogData (response, errorType) {
//   let data = {
//     api_endpoint: response.config.url,
//     request_time: response.duration,
//     error_type: errorType,
//     request_status: response.status,
//     app_route: window.location.pathname,
//     params: window.location.search.toString(),
//     user_agent: navigator.userAgent,
//     method: response.config.method
//   }
//   index.dispatch('setLog', data)
// }

export const HTTP = {
  get: (url, payload) => {
    if (!url.startsWith('/products?search=')) {
      EventBus.$emit('loader', true)
    }
    // {withCredentials: true}
    return axiosBasic.get(url, payload).then(response => {
      EventBus.$emit('loader', false)
      index.dispatch('getErrors', {})
      // console.log(response)
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      EventBus.$emit('loader', false)
      return Promise.reject(error)
    })
  },
  post: (url, payload) => {
    EventBus.$emit('loader', true)
    return axiosBasic.post(url, payload).then(response => {
      EventBus.$emit('loader', false)
      index.dispatch('getErrors', {})
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      EventBus.$emit('loader', false)
      return Promise.reject(error)
    })
  },
  put: (url, payload) => {
    EventBus.$emit('loader', true)
    return axiosBasic.put(url, payload).then(response => {
      index.dispatch('getErrors', {})
      EventBus.$emit('loader', false)
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      EventBus.$emit('loader', false)
      return Promise.reject(error)
    })
  },
  patch: (url, payload) => {
    EventBus.$emit('loader', true)
    return axiosBasic.patch(url, payload).then(response => {
      index.dispatch('getErrors', {})
      EventBus.$emit('loader', false)
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      EventBus.$emit('loader', false)
      return Promise.reject(error)
    })
  },
  delete: (url, payload) => {
    EventBus.$emit('loader', true)
    return axiosBasic.delete(url, payload).then(response => {
      EventBus.$emit('loader', false)
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      EventBus.$emit('loader', false)
      return Promise.reject(error)
    })
  }
}
