import ru from "vee-validate/dist/locale/ru.json";

export default {
    fields: {
        'email': "E-mail",
        'Email': 'Электронное письмо',
        'password': "Пароль",
        'phone': 'Номер телефона',
        'first_name': 'Имя',
        'deliveryCity': 'Населенный пункт',
        'deliveryWarehouses': 'Отделение',
        'deliveryStreet': 'Улица',
        'username': 'Имя пользователя',
        'review': 'Просмотр',
        'last_name': 'Фамилия',
        'password_confirmation': 'Подтверждение пароля',
        'shippingCity': 'Населенный пункт',
        'shippingWarehouses': 'Отделение'
    },
    validation: ru.messages,
    'Service': 'Сервис',
    'Shops': 'Магазины',
    'Stocks': 'Акции',
    'Payment and delivery': 'Доставка и оплата',
    'Wholesalers': 'Оптовикам',
    'My account': 'Мой аккаунт',
    'Login': 'Вход',
    "I'm looking for...": 'Я ищу...',
    'mo-fr': 'пн-пт',
    'sa-su': 'сб-вс',
    'mo-sa': 'пн-сб',
    'su': 'вс',
    'Got Question? Call us!': 'Есть вопрос? Звоните нам!',
    'Information': 'Информация',
    'Customer Service': 'Обслуживание',
    'Get In Tuch': 'Будем на связи',
    'Login to account': 'Войти в аккаунт',
    'Please register in order to checkout more quickly': 'Зарегистрируйтесь, чтобы быстрее Оформить заказ',
    'Your Email': 'Ваш Email',
    'Your Password': 'Ваш Пароль',
    'Lost your password?': 'Забыли пароль?',
    'Remember me': 'Запомните меня',
    'Sign Up for Newsletter': 'Подписаться на рассылку',
    'Register': 'Регистрация',
    'Confirm Password': 'Подтвердите Пароль',
    'Your Name': 'Ваше Имя',
    'Please enter your email to recovery password': 'Пожалуйста, введите адрес электронной почты для восстановления пароля',
    'About Us': 'О нас',
    'Checkout': 'Заказ',
    'Logout': 'Выйти',
    'CATALOG': 'КАТАЛОГ',
    'View Cart': 'Посмотреть корзину',
    'Total': 'Всего',
    'This page cannot be founded': 'Эта страница не может быть найдена',
    'Oops! The page you are looking for does not exist. It might have been moved or deleted.': 'К сожалению! Страница, которую вы ищете, не существует. Возможно, она был перемещена или удалена.',
    'Go Homepage': 'Перейти на главную страницу',
    'Contact Us': 'Связаться с нами',
    'Help': 'Помощь',
    'Terms & Conditions': 'Условия использования',
    'Returns': 'Возврат товара',
    'Shipping': 'Доставка',
    'Privacy Policy': 'Политика конфиденциальности',
    'Money-back': 'Возврат денег',
    'Payment Methods': 'Способы оплаты',
    'Credit': 'Кредит',
    'From cheap to expensive': 'От дешевых к дорогим',
    'From expensive to cheap': 'От дорогих к дешевым',
    'Popular': 'Популярные',
    'Novelty': 'Новинки',
    'Promotional': 'Акционные',
    'By rating': 'По рейтингу',
    'Show': 'Показывать',
    'Sort By': 'Сортировать по',
    'Subscribe to our newsletter and get the latest information first': 'Подпишись на наши новости и получай актуальную информацию первым',
    // 'Subscribe': 'Подписаться',
    'Your email address': 'Ваша электронная почта',
    'All Rights Reserved.': 'Все права защищены.',
    'Get in touch': 'Связаться',
    'Write Us A Message': 'Напишите нам сообщение',
    'Your Subject': 'Тема',
    'Your Phone': 'Ваш телефон',
    'Your message': 'Ваше сообщение',
    'The message was sent successfully!!!': 'Спасибо, мы получили Ваше сообщение и вскоре Вам ответим!!!',
    'Call us Now': 'Позвоните нам сейчас',
    'Email': 'Электронное письмо',
    'Our Address': 'Наш адрес',
    'Newsletter': 'Новостная рассылка',
    'Best Price': 'Лучшая цена',
    'Guaranteed price': 'Гарантированная цена',
    'Free shipping': 'Бесплатная доставка',
    'Orders over 5000 UAH': 'Заказы свыше 5000 ₴',
    'Sucure Payment': 'Безопасная оплата',
    '100% secure payment': '100% безопасный платеж',
    'Free Return': 'Бесплатный возврат',
    'Within 30 days returns': 'В течение 30 дней',
    'Home': 'Компания',
    'Items': 'Товар(ы)',
    'PRODUCT': 'ТОВАР',
    'NAME': 'ИМЯ',
    'UNIT PRICE': 'ЦЕНА ЗА ЕДИНИЦУ',
    'QUANTITY': 'КОЛИЧЕСТВО',
    'TOTAL': 'ОБЩЕЕ',
    'Continue shopping': 'Продолжить покупки',
    'You Pay': 'К оплате',
    'Apply': 'Применить',
    'Enter Your Coupon': 'Введите свой купон',
    'search': 'поиск',
    'search?': 'Что ищете?',
    'Contact data': 'Контактные данные',
    'Faq': 'Вопросы и ответы',

    'Sign in': 'Войти',
    'Sign up': 'Зарегистрироваться',
    'Email address': 'Адрес электронной почты',
    'Email Address': 'Адрес электронной почты',
    'Forgot password?': 'Забыли пароль?',
    'Hello': 'Здравствуйте',
    'My Account': 'Мой аккаунт',
    'Wishlist': 'Желания',
    'Orders History': 'История заказов',
    'Profile settings': 'Настройки профиля',
    'We can\'t seem to find the page you are looking for.': 'Мы не можем найти страницу, которую вы ищете.',
    'Homepage': 'Домашняя страница',
    'Return to homepage': 'Вернуться на главную страницу',
    'Search': 'Поиск',
    'Here are some helpful links instead': 'Вот некоторые полезные ссылки',
    'Find with advanced search': 'Найти с расширенным поиском',
    'Help & Support': 'Помощь и Поддержка',
    'Visit our help center': 'Посетите наш справочный центр',
    'Fast and high quality delivery': 'Быстрая и качественная доставка',
    'Free delivery for all orders over $200': 'Бесплатная доставка для всех заказов свыше 200 долларов',
    'Money back guarantee': 'Гарантия возврата денег',
    'We return money within 30 days': 'Мы возвращаем деньги в течение 30 дней',
    '24/7 customer support': '24/7 служба поддержки',
    'Friendly 24/7 customer support': 'Дружелюбная служба поддержки 24/7',
    'Secure online payment': 'Безопасный онлайн-платеж',
    'We possess SSL / Secure сertificate': 'У нас есть SSL / Безопасный сертификат',
    'Stay informed': 'Будь в курсе',
    'sub_info': 'Подпишитесь на нашу рассылку, чтобы получать ранние скидки, обновления и информацию о новых продуктах.',
    'Subscribe': 'Подписатся',
    'My Cart': 'Моя корзина',
    'Catalogs': 'Каталог',
    'Account & shipping info': 'Учетная запись и доставка',
    'Your account': 'Ваш аккаунт',
    'Shop': 'Магазин',
    'Services': 'Предоставляемые услуги',
    'Promotions': 'Акции',
    'Contacts': 'Контакты',
    'Help center': 'Центр помощи',
    'Address': 'Адрес',
    'prod_catalog': 'Каталог товаров',
    'Compare': 'Сравнение',
    'Categories': 'Категории',
    'Shop catalogs': 'Каталог',
    'Popular brands': 'Популярные бренды',
    'View all': 'Смотреть все',
    'Product Catalog': 'Каталог товаров',
    'Sort by': 'Сортировать',
    'Popularity': 'Популярные',
    'Low - Hight Price': 'Низкая Цена',
    'High - Low Price': 'Высокая Цена',
    'Price': 'Цена',
    'of': 'из',
    'products': 'товаров',
    'Main store address': 'Адрес магазина',
    'Working hours': 'Рабочее время',
    'Phone numbers': 'Телефонные номера',
    'Phone Number': 'Номер телефона',
    'New Password': 'Новый пароль',
    'Update profile': 'Обновить профиль',
    'Subscribe me to Newsletter': 'Подписаться на рассылку новостей',
    'Email addresses': 'Адреса электронной почты',
    'Drop us a line': 'Напишите нам',
    'Your name': 'Ваше имя',
    'Your phone': 'Ваш телефон',
    'Subject': 'Тема',
    'Message': 'Сообщение',
    'Partner outlet stores': 'Партнерские магазины',
    'Our stores': 'Наши магазины',
    'Delivery info': 'Информация о доставке',
    'Order tracking': 'Отслеживание заказа',
    'Refunds & replacements': 'Возврат и замена',
    'About us': 'О нас',
    'About company': 'О компании',
    'News': 'Новости',
    'Outlets': 'Магазины',
    'Affiliates': 'Филиалы',
    'Support': 'Служба поддержки',
    'Privacy': 'Конфиденциальность',
    'Terms of use': 'Условия эксплуатации',
    'My orders': 'Мои заказы',
    'Account': 'Профиль',
    'Orders history': 'История заказов',
    'Sort orders': 'Сортировать заказы',
    'Sign out': 'Выход',
    'Dashboard': 'Панель управления',
    'Account settings': 'Настройки учетной записи',
    'All': 'Все',
    'Delivered': 'Доставлены',
    'In Progress': 'В ходе выполнения',
    'Canceled': 'Отменены',
    'Orders': 'Заказы',
    'Profile info': 'Информация о профиле',
    'Addresses': 'Адреса',
    'How can we help?': 'Как мы можем помочь?',
    'Ask Questions. Browse Topics. Find Answers.': 'Задавать вопросы. Просмотр тем. Найти ответы.',
    'Ask your question': 'Задайте ваш вопрос',
    'Suggestions': 'Предложения',
    'Select a topic': 'Выберите тему',
    'F.A.Q.': 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ.',
    'Haven\'t found the answer? We can help.': 'Не нашли ответ? Мы можем помочь.',
    'Contact us and we’ll get back to you as soon as possible.': 'Напишите нам, и мы свяжемся с вами как можно скорее.',
    'Submit a request': 'Отправить запрос',
    'Russian': 'Русский',
    'Ukrainian': 'Украинский',
    'Your email': 'Ваш email',
    'Order traking': 'Отслеживание заказа',
    'Useful links': 'Полезные ссылки',
    'Password': 'Пароль',
    'Confirm password': 'Подтвердите пароль',
    'First name': 'Имя',
    'Last name': 'Фамилия',
    'First Name': 'Имя',
    'Last Name': 'Фамилия',
    'For customers': 'Для клиентов',
    'For support': 'Поддержка',
    'Click to see map': 'Нажмите, чтобы увидеть карту',
    'Send message': 'Отправить сообщение',
    'Provide short title of your request': 'Краткое название запроса',
    'Please describe in detail your request': 'Пожалуйста, опишите подробно ваш запрос',
    'Petrov Petr': 'Петров Петр',
    'Learn more': 'Узнать больше',
    'Forgot your password?': 'Забыли Ваш пароль?',
    'Change your password in three easy steps. This helps to keep your new password secure.': 'Измените свой пароль в три простых шага. Это помогает сохранить ваш новый пароль в безопасности.',
    'Fill in your email address below.': 'Заполните ваш адрес электронной почты ниже.',
    'We\'ll email you a temporary code.': 'Мы вышлем вам временный код.',
    'Use the code to change your password on our secure website.': 'Используйте код для изменения пароля на нашем веб-сайте.',
    'Enter your email address': 'Введите ваш адрес электронной почты',
    'Get new password': 'Получить новый пароль',
    'Password recovery request completed successfully.':'Запрос на восстановление пароля успешно выполнен.',
    'Success': 'Успешно',
    'Add to Cart': 'Добавить в корзину',
    'View details': 'Посмотреть детали',
    'Add to wishlist': 'Добавить в список желаний',
    'Add to Wishlist': 'Добавить в список желаний',
    'Newest': 'Новейшие',
    'Oldest': 'Старые',
    'Load more reviews': 'Загрузить больше отзывов',
    'Submit a Review': 'Отправить отзыв',
    'High rating': 'Высокий рейтинг',
    'Low rating': 'Низкий рейтинг',
    'Your name and surname': 'Ваше имя и фамилия',
    'To add an item to your wishlist, you need to login.': 'Авторизуйтесь для добавления товара в желаемые.',
    'Warning': 'Предупреждение',
    'Subtotal': 'Итог',
    'Discount': 'Скидка',
    // 'You may also like': 'Вам также может понравиться',
    'Expand cart': 'Корзина',
    'Back to Cart': 'Вернуться в корзину',
    'Proceed to Shipping': 'Перейти к доставке',
    'Quantity': 'Количество',
    'Back': 'Обратно',
    'Next': 'Следующий',
    'Order summary': 'Итог заказа',
    'Shipping address': 'Адрес доставки',
    'Additional comments': 'Дополнительные комментарии',
    'Note': 'Примечание',
    'Apply promo code': 'Применить промо-код',
    'Promo code': 'Промо-код',
    'Proceed to Checkout': 'Перейти к оформлению',
    'Your cart': 'Ваша корзина',
    'Your details': 'Ваши данные',
    'Payment': 'Оплата',
    'Review': 'Отзыв',
    'Products': 'Товары',
    'Cart': 'Корзина',
    'Remove': 'Удалить',
    'Update cart': 'Обновить корзину',
    'Search for products': 'Поиск товаров',
    'Product comparison': 'Сравнение продуктов',
    'Сomparison': 'Сравнение',
    'My wishlist': 'Мой список желаний',
    'List of items you added to wishlist': 'Список продуктов, которые вы добавили в список желаний',
    'My addresses': 'Мои адреса',
    'List of your registered addresses': 'Список ваших зарегистрированных адресов',
    'Actions': 'Действия',
    'Update you profile details below': 'Обновите данные своего профиля ниже',
    'Profile saved successfully': 'Профиль успешно сохранен',
    'Add new address': 'Добавить новый адрес',
    'Expand menu': 'Развернуть меню',
    'Related articles': 'Статьи по Теме',
    'Track order': 'Отследить заказ',
    'Go back shopping': 'Вернуться за покупками',
    'Thank you for your order!': 'Спасибо за ваш заказ!',
    'Your order has been placed and will be processed as soon as possible.': 'Ваш заказ был оформлен и будет обработан как можно скорее.',
    'Make sure you make note of your order number, which is': 'Убедитесь, что вы записали свой номер заказа, который',
    'You will be receiving an email shortly with confirmation of your order.': 'Вскоре вы получите электронное письмо с подтверждением вашего заказа.',
    'You can now': 'Вы можешь сейчас',
    'Popular services': 'Популярные предоставляемые услуги',
    'You may also like': 'Вам также может понравиться',
    'General Info': 'Общая информация',
    'Tech Specs': 'Характеристики',
    'Reviews': 'Отзывы',
    'Product available': 'Товар доступен',
    'Add to': 'Добавить в',
    'Customers recommended this product': 'Клиентов рекомендовали этот продукт',
    'out of': 'из',
    'Overall rating': 'Общий рейтинг',
    'Add a new address': 'Добавить новый адрес',
    'Make this address primary': 'Сделать этот адрес основным',
    'Company': 'Компания',
    'City': 'Город',
    '(street / house / apartments)': '(улица/дом/квартира)',
    'Close': 'Закрыть',
    'Add address': 'Добавить адрес',
    'Choose shipping method': 'Выберите способ доставки',
    'Shipping method': 'Способ доставки',
    'Delivery time': 'Срок поставки',
    'Handling fee': 'Плата за обработку',
    'From the location of the store': 'С места нахождения магазина',
    'Local Pickup from store': 'Самовывоз из магазина',
    'Local pickup from store': 'Самовывоз из магазина',
    'Courier delivery': 'Курьерская доставка',
    'Individually': 'Индивидуально',
    'up to one week': 'до одной недели',
    'Local courier shipping': 'Местная курьерская доставка',
    'days': 'дней',
    'All addresses (default zone)': 'Все адреса',
    'Review your order': 'Просмотрите заказ',
    'After order confirmation': 'После подтверждения заказа',
    'Shipping to': 'Доставка',
    'Client': 'Клиент',
    'Phone': 'Телефон',
    'Back to Shipping': 'Вернуться к доставке',
    'Payment method': 'Способ оплаты',
    'Complete order': 'Завершить заказ',
    'Complete': 'Завершить',
    'Write a review': 'Написать отзыв',
    'Shipping options': 'Способы доставки',
    'Share': 'Поделиться',
    'Will be displayed on the comment.': 'Будет отображаться в комментарии.',
    'Rating': 'Рейтинг',
    'Your review must be at least 30 characters.': 'Ваш отзыв должен содержать не менее 30 символов.',
    'Pros': 'Плюсы',
    'Cons': 'Минусы',
    'For authorized users only': 'Только для авторизованных пользователей',
    'Product added to cart.': 'Товар добавлен в корзину.',
    'Your review will be added after moderation.': 'Ваш отзыв будет добавлен после модерации.',
    'Notify me of replies by email': 'Уведомлять об ответах по электронной почте',
    'I recommend this product': 'Я рекомендую этот продукт',
    'Find us': 'Найди нас',
    'Call us': 'Позвоните нам',
    'Write us': 'Напишите нам',
    'Back to Addresses': 'Вернуться к адресам',
    'Back to Details': 'Вернуться к деталям',
    'Current email is used. Login to continue ordering.': 'Текущие емейл используется. Залогиньтесь для продолжения оформления заказа.',
    'Preview': 'Предпросмотр',
    'View': 'Просмотр',
    'Enter order id to search for information.': 'Введите идентификатор заказа для поиска информации',
    'Shipped via': 'Доставка',
    'Status': 'Статус',
    'Expected date': 'Дата выполнения',
    'First step': 'Первый шаг',
    'Second step': 'Второй шаг',
    'Third step': 'Третий шаг',
    'Fourth step': 'Четвертый шаг',
    'Order placed': 'Новый заказ',
    'Tracking order': 'Отслеживание заказа',
    'Processing order': 'Обработка заказа',
    'Order completed': 'Заказ выполнен',
    'Product dispatched': 'Товар отправлен',
    'Notify me when order is delivered.': 'Сообщите мне, когда заказ будет доставлен.',
    'View Order Details': 'Посмотреть детали заказа',
    'Order No': '№ заказа',
    'Date Purchased': 'Дата покупки',
    'Order #': '№ заказа',
    'New': 'Новый',
    'Cancelled': 'Отменен',
    'prod_count': 'товара(ов)',
    'Bestsellers': 'Бестселлеры',
    'New arrivals': 'Новые поступления',
    'Products catalog': 'Каталог товаров',
    'Top rated': 'Самые популярные',
    'Limited Offer': 'Ограниченное предложение',
    'Trending products': 'Актуальные продукты',
    'More products': 'Больше продуктов',
    'Read the blog': 'Читать блог',
    'Latest store, fashion news and trends': 'Актуальный шопинг, новости и тренды',
    'Refunds': 'Возвраты',
    'Delivery': 'Доставка',
    'View offers': 'Посмотреть предложения',
    'Shop Now': 'Купить сейчас',
    'Shop now': 'Купить сейчас',
    'View more': 'Посмотреть больше',
    'Follow on Instagram': 'Смотреть в Instagram',
    'Submit request': 'Отправить запрос',
    'help_info_msg': 'Наша дружная команда поддержки всегда готова помочь вам. Начните с выбора темы, с которой мы можем вам помочь.',
    'Privacy policy': 'Политика конфиденциальности',
    'Request Message': 'Сообщение запроса',
    'Request Subject': 'Тема запроса',
    'of users found this review helpful': 'пользователей считают этот отзыв полезным',
    'You have successfully subscribed to the newsletter.': 'Вы успешно подписались на рассылку.',
    'Blog': 'Блог',
    'Blog list': 'Список блогов',
    'All rights reserved.': 'Все права защищены.',
    'Made by': 'Сделано',
    'Authentication only - we won\'t spam you.': 'Только авторизованным - мы не будем вас спамить.',
    'Remove from wishlist': 'Удалить из списка желаний',
    'Select product category to compare.': 'Выберите категорию товаров для сравнения.',
    'Fast delivery': 'Быстрая доставка',
    'Quality of services provided': 'Качество предоставляемых услуг',
    'Wide range of goods': 'Широкий ассортимент товаров',
    'Country': 'Страна',
    'Brand': 'Бренд',
    'Main': 'Главный',
    'Successfully completed': 'Успешно выполнен',
    'Are you sure to delete this product?': 'Вы уверены, что хотите удалить этот продукт?',
    'No, Thanks': 'Нет, спасибо',
    'OK': 'Да',
    'The cart is empty. Please add products to continue.': 'Корзина пуста. Пожалуйста, добавьте продукты, чтобы продолжить.',
    'Order ID': 'ID заказа',
    '< Prev': '< Предыдущая',
    'Next >': 'Следующая >',
    'Menu': 'Меню',
    'Sending': 'Отправлен',
    'Sales experience': 'Опыт продаж',
    'Years of sales experience': 'Многолетний опыт продаж',
    'High product quality': 'Высокое качество продукции',
    'Product quality': 'Качество продукции',
    'Wide range of products': 'Широкий ассортимент товаров',
    'Assortment of goods': 'Ассортимент товаров',
    'Flexibility and individual approach to each client': 'Гибкость и индивидуальный подход к каждому клиенту',
    'Approach to each client': 'Подход к каждому клиенту',
    'main_addr': 'Украина, Кропивницкий, ул. Волкова, 13В',
    'Search, Select, Buy online': 'Поиск, выбор, покупка онлайн',
    'View products': 'Посмотреть продукты',
    'about_us_first': 'Сеть строительных магазинов Зодчий в городе Кропивницкий предоставляет на Ваш выбор товары от лучших мировых производителей плитки, светильников, обоев, линолеума, паркета, и множество сопутствующих товаров для создания интерьера Вашей мечты. Менеджеры-консультанты магазина Зодчий в Кропивницком с радостью помогут Вам подобрать товар на любой вкус, а также бюджет. С подробным описанием товаров Вы можете ознакомиться на нашем сайте, а еще заказать 3D проект, оформить доставку, оплатить заказ, и получить его в ближайшее время. Мы работаем для Вас каждый день!',
    'about_us_second_title': 'Приобретая покупки онлайн Вы получаете много преимуществ',
    'about_us_second_p1': 'широкий ассортимент продукции на одном сайте, что экономит ваше время и силы в поисках нужного товара;',
    'about_us_second_p2': 'гарантированно качественная продукция;',
    'about_us_second_p3': 'доставка стройматериалов транспортом компании или услугами курьера в нужный Вам адрес и предварительно оговоренное заранее время или же самовывоз из магазина сети Зодчий в Кропивницком.',
    'Guarantee': 'Гарантия',
    'Review order': 'Просмотреть заказ',
    'Info': 'Информация',
    'Order Design': 'Заказать дизайн',
    'select file': 'выберите файл',
    'file with a size less than 5mb': 'файл размером менее 5 МБ',
    'send_service_request': 'Отправить запрос',
    'service_request_success': 'Запрос отправлен успешно.',
    'Leave a request': 'Оставьте заявку',
    'Collections': 'Коллекции',
    'Description': 'Описание',
    'nova_poshta': 'Новая Почта',
    'to branch': 'на отделение',
    'to_np_branch': 'На отделение Новой Почты',
    'by the address': 'по адресу',
    'Local Pickup': 'Самовывоз',
    'Courier shipping': 'Курьерская доставка',
    'enter the settlement of Ukraine': 'введите населенный пункт Украины',
    'Warehouse': 'Отделение',
    'Street': 'Улица',
    'Select location': 'Выберите населенный пункт',
    'Select warehouse': 'Выберите отделение',
    'House': 'Дом',
    'Apartment': 'Квартира',
    'This value must be phone': 'Это значение должно быть телефон',
    'Shipping Type': 'Тип доставки',
    'Fill in all required fields': 'Заполните все обязательные поля',
    'New collection': 'Новая коллекция',
    'Other collections': 'Другие коллекции',
    'About collection': 'О коллекции',
    'Here you will find products from the following categories': 'Здесь вы найдете товары из следующих категорий',
    'Too Many Attempts.': 'Много запросов, попробуйде позже.',
    'Ok': 'Ок',
    'Zodchiy - Shop': 'Zodchiy - Каталог товаров',
    '< ': '< ',
    ' >': ' >',
    'Petrov': 'Петров',
    'Petr': 'Петя',
    'Password recovery': 'Восстановление пароля',
    'Catalog': 'Каталог',
    'payment_and_delivery': 'Оплата и доставка',
    'countdown_d': 'д',
    'countdown_h': 'ч',
    'countdown_m': 'м',
    'countdown_s': 'с',
    'Is collection': 'Коллекция',
    'Availability': 'В наличии',
    'm_square_meter': 'кв.м',
    'm_thing': 'шт.',
    'm_roll': 'рулон.',
    'm_packaging': 'упаковка.',
    'Collection elements': 'Элементы коллекции',
    'Select collection element': 'Выберите элемент коллекции',
    'Back to collection': 'Вернуться в коллекцию',
    'Loading': 'Загрузка',
    'without_price': 'Цену уточняйте.',
    'Promotion is over': 'Акция окончена',
    'Code': 'Код',
    'Until the end': 'До окончания',
    'Found in category': 'Найдено в категории',
    'Delivery method': 'Способ доставки',
    'Recipient information': 'Информация о получателе',
    'Comment': 'Комментарий',
    'Ordering': 'Заказ',
    'Comment for order': 'Комментарий к заказу',
    'From': 'От',
    'Bad recaptcha': 'Низкий уровень доверия к браузеру!',
    'Contact informations': 'Контактная информация',
    'Shipping information': 'Информация о доставке',
    'Payment information': 'Платежная информация',
    'online_payment': 'Онлайн оплата',
    'on_delivery': 'Оптала при получении',
    'Pay': 'Оплатить',
    // 'Payment and delivery': 'Оплата и доставка'
    // 'Terms of use': 'Условия эксплуатации',
};