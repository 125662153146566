// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        activeFilters: JSON.parse(localStorage.getItem('activeFilters')) ?? [],
    },
    getters: {
        activeFilters: state => state.activeFilters,
    },
    mutations: {
        SET_FILTERS (state, data) {
            state.activeFilters = data
            localStorage.setItem('activeFilters', JSON.stringify(data))
        }
    },
    actions: {
        setFilters ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_FILTERS', data)
            })
        }
    },
  };