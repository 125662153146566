// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP, axiosBasic } from '@/api/http_utils'
import axios from 'axios'
import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        catalogs: {}
    },
    getters: {
        catalogs: state => state.catalogs,
    },
    mutations: {
        SET_CATALOGS (state, data) {
            state.catalogs = data
        }
    },
    actions: {
        getCatalogs ({ commit }, data) {
            return new Promise((resolve, reject) => {
                // var setupTime = localStorage.getItem('setupCatalogsTime')
                // var vuexData = JSON.parse(localStorage.getItem('vuex')) ?? {}
                // console.log(!'catalogs' in vuexData['catalog'] || vuexData['catalog']['catalogs'] === {})

                // if ((setupTime && (new Date().getTime() - setupTime > 5 * 60 * 1000)) || Object.keys(vuexData['catalog']['catalogs']).length === 0) {
                //     HTTP.get('/catalogs').then((response) => {
                //         commit('SET_CATALOGS', response)
                //         resolve(response)
                //     }).catch(error => {
                //         reject(error)
                //     })
                //     localStorage.setItem('setupCatalogsTime', new Date().getTime())
                // }
                HTTP.get('/catalogs').then((response) => {
                    commit('SET_CATALOGS', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalogs/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // getProducts ({ commit }, [Id, data]) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get(`/catalogs/${Id}/products?category_id=${data['category_id']}&page=${data['page']}`).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // getProductsByIds ({ commit }, ids) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get(`/catalogs/${Id}/products` + ids).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
    },
  };