<template>
    <div>
        <!-- Page Title (Light)-->
        <div class="bg-secondary">
            <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
                <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item"><router-link :to="{name: 'Home'}" class="text-nowrap"><i class="czi-home"></i>{{ $t('Home') }}</router-link></li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">{{ $t('Services') }}</li>
                    </ol>
                </nav>
                </div>
                <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
                <h1 class="h3 mb-0">{{ $t('Services') }}</h1>
                </div>
            </div>
        </div>
        <!-- Page Content-->
        <div class="container pb-5 mb-2 mb-md-4">
        <!-- Featured posts carousel-->

        <hr class="mt-5">
        <!-- Entries list-->
        <div class="row mt-3 mb-3">
            <template v-for="(service, index) in services">
                <div class="col-md-4 col-sm-6 mb-grid-gutter" :key="index">
                        <article>
                            <router-link :to="{name: 'ServiceView', params: { serviceId: service.id } }" class="blog-entry-thumb mb-3">
                                <el-image
                                    class="rounded-img"
                                    :src="mainImageUrl(service, 'service')"
                                    :lazy="false"
                                    fit="contain">
                                </el-image>
                            </router-link>
                            <div class="d-flex justify-content-between mb-2 pt-1">
                                <h2 class="h5 blog-entry-title mb-0">
                                    <router-link :to="{name: 'ServiceView', params: { serviceId: service.id } }">
                                        {{ service.title }}
                                    </router-link>
                                </h2>
                                <span class="blog-entry-meta-link font-size-sm text-nowrap ml-3 pt-1">
                                    <i class="czi-eye"></i>{{ service.views }}
                                </span>
                            </div>
                        </article>
                </div>
            </template>
        </div>

        <hr class="mb-5">
        </div>
    </div>
</template>
<script>
export default {
    name: 'Services',
    data () {
        return {
            services: [],
            titleTemplate: this.$i18n.t('Services')
        }
    },
    metaInfo () {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Зодчий Кропивницкий | Zodchiy Кропивницкий',
            // all titles will be injected into this template
            titleTemplate: '%s коллекция - ' + this.titleTemplate,
            meta: [
                { vmid: 'keywords', name: 'keywords', content: 'зодчий сервис, сервис зодчий, сервис кропивникций, спектр услуг, сервисов, zodchiy сервисы, сервисы zodchiy, предоставляемые услуги зодчий, услуги зодчий, зодчий услуша, кропивницкий услуга, товраы услуги, предоставляемые услуги, предоставляемые услуги кропивницкий, 3d визуализация, фотопечать на обоях, фотопечать на стекле' },
                { vmid: 'description', name: 'description', content: 'Зодчий кропивницкий - сервисы и предоставляемые услуги, сервис кропивникий, услуги кропивникий, 3d визуализация, фотопечать на обоях, фотопечать на стекле' }
            ]
        }
    },
    created () {
        this.getServices()
    },
    methods: {
        getServices () {
            this.$store.dispatch('service/getList').then((response) => {
                this.services = response
            }).catch((errors) => {
				console.log(errors);
            })
        }
    }
 }
</script>
<style scoped>
.rounded-img {
    border-radius: .4375rem !important;
}
</style>