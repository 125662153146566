// import Home from '@/views/Home.vue'
import Services from '@/components/Service/Services'
import RouteViewComponent from '@/components/RouteViewComponent.vue'
import { i18n } from '../plugins/i18n.js';
import store from '../store';


export default [
    {
        path: '/',
        component: RouteViewComponent,
        // beforeEnter (to, from, next) {
        //     let lang = to.params.lang

        //     if (!lang) {
        //         lang = store.state.language.language || 'ru'
        //     }

        //     if (!['ru', 'uk'].includes(lang)) {
        //         lang = 'ru'
        //     }
        //     // console.log(store.state.language.language)

        //     // console.log(store.state.language.language)
        //     if (store.state.language.language !== lang) {
        //         store.dispatch('language/setLanguage', lang)
        //         .then(() => {
        //             i18n.locale = store.state.language.language;
        //             next();
        //         });
        //     } else {
        //         next();
        //     }
        //     // next(lang);
        // },
        children: [
            {
                path: '',
                name: 'Home',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Home'
                },
                component: () => import( '@/components/Home')
            },
            {
                path: 'account/orders',
                name: 'AccountOrders',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Account orders'
                },
                component: () => import( '@/components/Account/Orders')
            },
            {
                path: 'account/wishlist',
                name: 'AccountWishlist',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Account wishlist'
                },
                component: () => import( '@/components/Account/Wishlist')
            },
            {
                path: 'account/profile',
                name: 'AccountProfileInfo',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Account profile'
                },
                component: () => import( '@/components/Account/ProfileInfo')
            },
            {
                path: 'account/address',
                name: 'AccountAddress',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Account address'
                },
                component: () => import( '@/components/Account/Address')
            },
            {
                path: 'password/recovery',
                name: 'PasswordRecovery',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Password recovery'
                },
                component: () => import( '@/components/PasswordRecovery')
            },
            {
                path: 'about',
                name: 'AboutUs',
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - About Us'
                },
                component: () => import( '@/components/AboutUs')
            },
            {
                path: 'contacts',
                name: 'Contacts',
                component: () => import( '@/components/Contacts'),
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Contacts'
                }
            },
            {
                path: 'guarantee',
                name: 'Guarantee',
                component: () => import( '@/components/Guarantee'),
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Guarantee'
                }
            },
            {
                path: 'payment-delivery',
                name: 'PaymentAndDelivery',
                component: () => import( '@/components/PaymentAndDelivery'),
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Payment and delivery'
                }
            },
            {
                path: 'privacy-policy',
                name: 'PrivacyPolicy',
                component: () => import( '@/components/PrivacyPolicy'),
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Privacy and Policy'
                }
            },
            {
                path: 'terms-use',
                name: 'TermsOfUse',
                component: () => import( '@/components/TermsOfUse'),
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Terms of Use'
                }
            },
            {
                path: 'help',
                name: 'helpCenter',
                meta: {
                    scrollToTop: true,
                    keepAlive: true,
                    title: 'Zodchiy - Help center'
                },
                component: () => import( '@/components/Help/List'),
            },
            {
                path: 'help/submit',
                name: 'helpSubmit',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Help Submit'
                },
                component: () => import( '@/components/Help/Submit')
            },
            {
                path: 'help/:categoryId/:faqId?',
                name: 'helpCategory',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Help'
                },
                component: () => import( '@/components/Help/Category')
            },
            {
                path: 'shop/catalog',
                name: 'ShopCatalog',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Shop catalog'
                 },
                component: () => import( '@/components/Shop/ShopCatalog')
            },
            {
                path: 'shop',
                name: 'Shop',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Shop'
                },
                component: () => import( '@/components/Shop/Shop')
            },
            {
                path: 'shop/cart',
                name: 'ShopCart',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Shop cart'
                },
                component: () => import( '@/components/Shop/Cart')
            },
            {
                path: 'shop/:catalogHumanUrl/:categoryId/:filterParams?',
                name: 'ShopMain',
                meta: {
                    scrollToTop: true,
                    // scrolFromOtherPages: true,
                    title: 'Zodchiy - Shop'
                },
                component: () => import( '@/components/Shop/Shop'),
                // children: [
                //     {

                //     }
                // ]
            },
            {
                path: 'products/comparison',
                name: 'ProductComparison',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Product comparison'
                },
                component: () => import( '@/components/Product/Comparison'),
            },
            {
                path: 'products/:productId',
                name: 'ProductView',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Product'
                },
                component: () => import( '@/components/Product/ProductView'),
            },
            {
                path: 'tracking',
                name: 'OrderTracking',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Order tracking'
                },
                component: () => import( '@/components/Tracking'),
            },
            {
                path: 'tracking/:orderNumber',
                name: 'OrderTrackingById',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Tracking'
                },
                component: () => import( '@/components/OrderTracking'),
            },
            {
                path: 'blog',
                name: 'BlogList',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Blogs'
                },
                component: () => import( '@/components/Blog/BlogList'),
            },
            {
                path: 'blog/:humanUrl',
                name: 'PostView',
                meta: {
                    scrollToTop: true,
                    title: 'Zodchiy - Blog'
                },
                component: () => import( '@/components/Blog/PostView'),
            },
            {
                path: 'checkout',
                component: RouteViewComponent,
                children: [
                    {
                        path: 'details',
                        name: 'CheckoutDetails',
                        component: () => import( '@/components/Checkout/Details'),
                        meta: {
                            scrollToTop: true,
                            title: 'Zodchiy - checkout details'
                        }
                    },
                    // {
                    //     path: 'shipping',
                    //     name: 'CheckoutShipping',
                    //     component: () => import( '@/components/Checkout/Shipping'),
                    //     meta: {
                    //         scrollToTop: true,
                    //         title: 'Zodchiy - checkout shipping'
                    //     }
                    // },
                    // {
                    //     path: 'payment',
                    //     name: 'CheckoutPayment',
                    //     component: () => import( '@/components/Checkout/Payment'),
                    //     meta: {
                    //         scrollToTop: true,
                    //         title: 'Zodchiy - checkout payment'
                    //     }
                    // },
                    // {
                    //     path: 'review',
                    //     name: 'CheckoutReview',
                    //     component: () => import( '@/components/Checkout/Review'),
                    //     meta: {
                    //         scrollToTop: true,
                    //         title: 'Zodchiy - checkout review'
                    //     }
                    // },
                    {
                        path: 'complete/:orderNumber',
                        name: 'CheckoutComplete',
                        component: () => import( '@/components/Checkout/Complete'),
                        meta: {
                            scrollToTop: true,
                            title: 'Zodchiy - checkout complate'
                        }
                    },
                ]
            },
            {
                path: 'services',
                name: 'Services',
                component:  Services,
                meta: {
                    keepAlive: true,
                    scrollToTop: true,
                    title: 'Zodchiy - Services'
                }
            },
            {
                path: 'services/:serviceId',
                name: 'ServiceView',
                component: () => import( '@/components/Service/ServiceView'),
                meta: {
                    keepAlive: true,
                    scrollToTop: true,
                    title: 'Zodchiy - Service'
                }
            },
            {
                path: 'promotions',
                name: 'Promotions',
                component: () => import( '@/components/Promotion/PromotionList'),
                meta: {
                    // keepAlive: true,
                    scrollToTop: true,
                    title: 'Zodchiy - Promotions'
                }
            },
            {
                path: 'promotions/:promotionId',
                name: 'PromotionView',
                component: () => import( '@/components/Promotion/PromotionView'),
                meta: {
                    // keepAlive: true,
                    scrollToTop: true,
                    title: 'Zodchiy - Promotion'
                }
            },
            {
                path: 'collections',
                name: 'Collections',
                component: () => import( '@/components/Collection/Collections'),
                meta: {
                    keepAlive: false,
                    scrollToTop: true,
                    title: 'Zodchiy - Collections'
                }
            },
            {
                path: 'collections/:collectionId',
                name: 'CollectionView',
                component: () => import( '@/components/Collection/CollectionView'),
                meta: {
                    keepAlive: false,
                    scrollToTop: true,
                    title: 'Zodchiy - Collections'
                }
            },
            {
                path: '*',
                redirect: {
                    name: 'notFound'
                }
            },
            {
                path: 'not-found',
                name: 'notFound',
                component: () => import( '@/components/NotFound'),
                meta: {
                    keepAlive: true,
                    scrollToTop: true,
                    title: 'Zodchiy - Not found'
                }
            },
        ]
    }
    // {
    //     path: '/',
    //     redirect: {
    //         path: '/'
    //     }
    // }
];

// export default [
//     {
//         path: '/:lang',
//         component: RouteViewComponent,
//         beforeEnter (to, from, next) {
//             let lang = to.params.lang

//             if (!lang) {
//                 lang = store.state.language.language || 'ru'
//             }

//             if (!['ru', 'uk'].includes(lang)) {
//                 lang = 'ru'
//             }
//             // console.log(store.state.language.language)

//             // console.log(store.state.language.language)
//             if (store.state.language.language !== lang) {
//                 store.dispatch('language/setLanguage', lang)
//                 .then(() => {
//                     i18n.locale = store.state.language.language;
//                     next();
//                 });
//             } else {
//                 next();
//             }
//             // next(lang);
//         },
//         children: [
//             {
//                 path: '',
//                 name: 'Home',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Home'
//                 },
//                 component: () => import( '@/components/Home')
//             },
//             {
//                 path: 'account/orders',
//                 name: 'AccountOrders',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Account orders'
//                 },
//                 component: () => import( '@/components/Account/Orders')
//             },
//             {
//                 path: 'account/wishlist',
//                 name: 'AccountWishlist',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Account wishlist'
//                 },
//                 component: () => import( '@/components/Account/Wishlist')
//             },
//             {
//                 path: 'account/profile',
//                 name: 'AccountProfileInfo',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Account profile'
//                 },
//                 component: () => import( '@/components/Account/ProfileInfo')
//             },
//             {
//                 path: 'account/address',
//                 name: 'AccountAddress',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Account address'
//                 },
//                 component: () => import( '@/components/Account/Address')
//             },
//             {
//                 path: 'password/recovery',
//                 name: 'PasswordRecovery',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Password recovery'
//                 },
//                 component: () => import( '@/components/PasswordRecovery')
//             },
//             {
//                 path: 'about',
//                 name: 'AboutUs',
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - About Us'
//                 },
//                 component: () => import( '@/components/AboutUs')
//             },
//             {
//                 path: 'contacts',
//                 name: 'Contacts',
//                 component: () => import( '@/components/Contacts'),
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Contacts'
//                 }
//             },
//             {
//                 path: 'guarantee',
//                 name: 'Guarantee',
//                 component: () => import( '@/components/Guarantee'),
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Guarantee'
//                 }
//             },
//             {
//                 path: 'payment-delivery',
//                 name: 'PaymentAndDelivery',
//                 component: () => import( '@/components/PaymentAndDelivery'),
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Payment and delivery'
//                 }
//             },
//             {
//                 path: 'privacy-policy',
//                 name: 'PrivacyPolicy',
//                 component: () => import( '@/components/PrivacyPolicy'),
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Privacy and Policy'
//                 }
//             },
//             {
//                 path: 'terms-use',
//                 name: 'TermsOfUse',
//                 component: () => import( '@/components/TermsOfUse'),
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Terms of Use'
//                 }
//             },
//             {
//                 path: 'help',
//                 name: 'helpCenter',
//                 meta: {
//                     scrollToTop: true,
//                     keepAlive: true,
//                     title: 'Zodchiy - Help center'
//                 },
//                 component: () => import( '@/components/Help/List'),
//             },
//             {
//                 path: 'help/submit',
//                 name: 'helpSubmit',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Help Submit'
//                 },
//                 component: () => import( '@/components/Help/Submit')
//             },
//             {
//                 path: 'help/:categoryId/:faqId?',
//                 name: 'helpCategory',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Help'
//                 },
//                 component: () => import( '@/components/Help/Category')
//             },
//             {
//                 path: 'shop/catalog',
//                 name: 'ShopCatalog',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Shop catalog'
//                  },
//                 component: () => import( '@/components/Shop/ShopCatalog')
//             },
//             {
//                 path: 'shop',
//                 name: 'Shop',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Shop'
//                 },
//                 component: () => import( '@/components/Shop/Shop')
//             },
//             {
//                 path: 'shop/cart',
//                 name: 'ShopCart',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Shop cart'
//                 },
//                 component: () => import( '@/components/Shop/Cart')
//             },
//             {
//                 path: 'shop/:catalogHumanUrl/:categoryId/:filterParams?',
//                 name: 'ShopMain',
//                 meta: {
//                     scrollToTop: true,
//                     // scrolFromOtherPages: true,
//                     title: 'Zodchiy - Shop'
//                 },
//                 component: () => import( '@/components/Shop/Shop'),
//                 // children: [
//                 //     {

//                 //     }
//                 // ]
//             },
//             {
//                 path: 'products/comparison',
//                 name: 'ProductComparison',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Product comparison'
//                 },
//                 component: () => import( '@/components/Product/Comparison'),
//             },
//             {
//                 path: 'products/:productId',
//                 name: 'ProductView',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Product'
//                 },
//                 component: () => import( '@/components/Product/ProductView'),
//             },
//             {
//                 path: 'tracking',
//                 name: 'OrderTracking',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Order tracking'
//                 },
//                 component: () => import( '@/components/Tracking'),
//             },
//             {
//                 path: 'tracking/:orderNumber',
//                 name: 'OrderTrackingById',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Tracking'
//                 },
//                 component: () => import( '@/components/OrderTracking'),
//             },
//             {
//                 path: 'blog',
//                 name: 'BlogList',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Blogs'
//                 },
//                 component: () => import( '@/components/Blog/BlogList'),
//             },
//             {
//                 path: 'blog/:humanUrl',
//                 name: 'PostView',
//                 meta: {
//                     scrollToTop: true,
//                     title: 'Zodchiy - Blog'
//                 },
//                 component: () => import( '@/components/Blog/PostView'),
//             },
//             {
//                 path: 'checkout',
//                 component: RouteViewComponent,
//                 children: [
//                     {
//                         path: 'details',
//                         name: 'CheckoutDetails',
//                         component: () => import( '@/components/Checkout/Details'),
//                         meta: {
//                             scrollToTop: true,
//                             title: 'Zodchiy - checkout details'
//                         }
//                     },
//                     // {
//                     //     path: 'shipping',
//                     //     name: 'CheckoutShipping',
//                     //     component: () => import( '@/components/Checkout/Shipping'),
//                     //     meta: {
//                     //         scrollToTop: true,
//                     //         title: 'Zodchiy - checkout shipping'
//                     //     }
//                     // },
//                     // {
//                     //     path: 'payment',
//                     //     name: 'CheckoutPayment',
//                     //     component: () => import( '@/components/Checkout/Payment'),
//                     //     meta: {
//                     //         scrollToTop: true,
//                     //         title: 'Zodchiy - checkout payment'
//                     //     }
//                     // },
//                     // {
//                     //     path: 'review',
//                     //     name: 'CheckoutReview',
//                     //     component: () => import( '@/components/Checkout/Review'),
//                     //     meta: {
//                     //         scrollToTop: true,
//                     //         title: 'Zodchiy - checkout review'
//                     //     }
//                     // },
//                     {
//                         path: 'complete/:orderNumber',
//                         name: 'CheckoutComplete',
//                         component: () => import( '@/components/Checkout/Complete'),
//                         meta: {
//                             scrollToTop: true,
//                             title: 'Zodchiy - checkout complate'
//                         }
//                     },
//                 ]
//             },
//             {
//                 path: 'services',
//                 name: 'Services',
//                 component:  Services,
//                 meta: {
//                     keepAlive: true,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Services'
//                 }
//             },
//             {
//                 path: 'services/:serviceId',
//                 name: 'ServiceView',
//                 component: () => import( '@/components/Service/ServiceView'),
//                 meta: {
//                     keepAlive: true,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Service'
//                 }
//             },
//             {
//                 path: 'promotions',
//                 name: 'Promotions',
//                 component: () => import( '@/components/Promotion/PromotionList'),
//                 meta: {
//                     // keepAlive: true,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Promotions'
//                 }
//             },
//             {
//                 path: 'promotions/:promotionId',
//                 name: 'PromotionView',
//                 component: () => import( '@/components/Promotion/PromotionView'),
//                 meta: {
//                     // keepAlive: true,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Promotion'
//                 }
//             },
//             {
//                 path: 'collections',
//                 name: 'Collections',
//                 component: () => import( '@/components/Collection/Collections'),
//                 meta: {
//                     keepAlive: false,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Collections'
//                 }
//             },
//             {
//                 path: 'collections/:collectionId',
//                 name: 'CollectionView',
//                 component: () => import( '@/components/Collection/CollectionView'),
//                 meta: {
//                     keepAlive: false,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Collections'
//                 }
//             },
//             {
//                 path: '*',
//                 redirect: {
//                     name: 'notFound'
//                 }
//             },
//             {
//                 path: 'not-found',
//                 name: 'notFound',
//                 component: () => import( '@/components/NotFound'),
//                 meta: {
//                     keepAlive: true,
//                     scrollToTop: true,
//                     title: 'Zodchiy - Not found'
//                 }
//             },
//         ]
//     },
//         // {
//         //     path: '/',
//         //     redirect: {
//         //         path: i18n.locale || store.state.language.language
//         //     }
//         // },
//     {
//         path: '/',
//         redirect: {
//             path: '/' //  + store.state.language.language && store.state.language.language !== null ? store.state.language.language : i18n.locale ? i18n.locale : 'ru'
//         }
//     }
// ];