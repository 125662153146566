import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations';

Vue.use(VueI18n)

let i18n = new VueI18n({
  locale: 'uk',
  fallbackLocale: 'uk',
  messages
});

export { i18n }