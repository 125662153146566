// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        wishlists: JSON.parse(localStorage.getItem('wishlists')) ?? [],
        wishlistsSubTotal: 0,
    },
    getters: {
        wishlists: state => state.wishlists,
        wishlistsSubTotal: state => state.wishlists ? state.wishlists.length : 0
    },
    mutations: {
        SET_WISHLISTS (state, data) {
            state.wishlists = data
            localStorage.setItem('wishlists', JSON.stringify(data))
        },
        ADD_WISHLIST (state, data) {
            state.wishlists.push(data)
            localStorage.setItem('wishlists', JSON.stringify(state.wishlists))
        },
        DELETE_WISHLIST (state, id) {
            const record = state.wishlists.find(p => p.id === id)
            if (record) {
                state.wishlists.splice(state.wishlists.indexOf(record), 1)
                localStorage.setItem('wishlists', JSON.stringify(state.wishlists))
            }
        }
    },
    actions: {
        getList ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/wishlists').then((response) => {
                    commit('SET_WISHLISTS', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/wishlists/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/wishlists', data).then((response) => {
                    commit('ADD_WISHLIST', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/wishlists/' + Id).then((response) => {
                    commit('DELETE_WISHLIST', Id)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };