import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import { mapGetters } from 'vuex'
import { i18n } from './plugins/i18n.js'
import VueSweetalert2 from 'vue-sweetalert2'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VueAnalytics from 'vue-analytics'
import ProductZoomer from 'vue-product-zoomer'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import VueCountdown from '@chenfengyuan/vue-countdown';
import ZoomOnHover from "vue-zoom-on-hover";
import { VueReCaptcha } from 'vue-recaptcha-v3'
var VueScrollTo = require('vue-scrollto')
import VueTheMask from 'vue-the-mask'

// validator block
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import "./vee-validate";

Vue.prototype.moment = moment
Vue.prototype.$apiErrors = {}
Vue.prototype.$fileUrl = process.env.VUE_APP_FILE_URL
Vue.prototype.$storageUrl = process.env.VUE_APP_STORAGE_URL

Vue.use(VueMeta)
Vue.use(VueScrollTo)
Vue.use(ElementUI, { locale })
Vue.use(VueSweetalert2)
Vue.use(VueSocialSharing)
Vue.use(ProductZoomer)
Vue.use(ZoomOnHover)
Vue.use(VueTheMask)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  loaderOptions: {
    // useRecaptchaNet: true
    autoHideBadge: true
  }
})
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component(VueCountdown.name, VueCountdown);

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    priceFull (price) {
      return Math.trunc(price)
    },
    priceDivision (price) {
      let data = Number((price-Math.trunc(price)).toFixed(2)).toString().split(".")[1]
      if (data) {
        return data
      }
      return '00'
    },
    mainImageUrl(objectData, objectType) {
      switch (objectType) {
        case 'product':
          if ('main_image' in objectData && objectData['main_image'] !== null && objectData['main_image']['path']) {
            return this.$fileUrl + objectData['main_image']['path']
          }
          return null
        case 'promotion':
          if ('images' in objectData && objectData['images'].length > 0) {
            return this.$fileUrl + objectData['images'][0]['path']
          }
          return null
        case 'service':
          if ('images' in objectData && objectData['images'].length > 0) {
            return this.$fileUrl + objectData['images'][0]['path']
          }
          return null
        case 'slider':
          if ('main_image' in objectData && objectData['main_image'] !== null && objectData['main_image']['path']) {
            return this.$fileUrl + objectData['main_image']['path']
          }
          return null
        case 'collection':
          if ('main_image' in objectData && objectData['main_image'] !== null && objectData['main_image']['path']) {
            return this.$fileUrl + objectData['main_image']['path']
          }
          return null
        case 'catalog':
          if ('images' in objectData && objectData['images'].length > 0) {
            return this.$fileUrl + objectData['images'][0]['path']
          }
          return null
        case 'category':
          if ('images' in objectData && objectData['images'].length > 0) {
            return this.$fileUrl + objectData['images'][0]['path']
          }
          return null
        case 'processing':
          return null
        case 'sending':
          return null
        default:
          return null
      }
    },
    imageUrl(path) {
      if (path) {
        return this.$fileUrl + path
      }
      return null
    }
  },
  // computed: {
  //   isAuth () {
  //     return this.$store.getters['auth/isAuthenticated']
  //   },
  //   Profile () {
  //     return this.$store.getters['account/Profile']
  //   },
  //   catalogsList () {
  //     return this.$store.getters['catalog/catalogs']
  //   },
  //   wishlistsSubTotal () {
  //     return this.$store.getters['wishlist/wishlistsSubTotal']
  //   },
  // }
  computed: {
    ...mapGetters({
      isAuth: 'auth/isAuthenticated',
      Profile: 'account/Profile',
      // cartProducts: 'cart/cartProducts',
      // cartSubTotal: 'cart/cartSubTotal',
      // wishlistsSubTotal: 'wishlist/wishlistsSubTotal',
      wishlists: 'wishlist/wishlists',
      comparisonCount: 'comparison/comparisonCount',
      comparisonList: 'comparison/comparisonList',
      getApiErrors: 'apiErrors',
      // ordersSubTotal: 'order/ordersSubTotal',
      countriesList: 'countriesList'
    }),
  }
})
Vue.use(VueAnalytics, {
  // id: 'UA-189510638-1',
  id: 'UA-143415303-2',
  router,
  autoTracking: {
    shouldRouterUpdate (to, from) {
      // Here I'm allowing tracking only when
      // next route path is not the same as the previous
      return to.path !== from.path
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  // methods: {
  //   afterLeave () {
  //     this.$root.$emit('triggerScroll')
  //   }
  // }
}).$mount('#app')