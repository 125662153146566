<template>
    <div class="product-price">
        <span class="text-accent">
            <span v-if="product.without_price">
                <small>{{ $t('without_price') }}</small>
            </span>
            <span v-else>
                <template v-if="product['discount'] !== null">
                    <s><span v-if="product.price_from">{{ $t('From') }}</span>{{ priceFull(product['price']) }}.<small>{{
                        priceDivision(product['price']) }}</small> ₴</s>
                    <span class="text-danger"><small> {{ priceFull(product['discount']['price']) }}.{{
                        priceDivision(product['discount']['price']) }}</small> ₴</span>
                    <template v-if="withMeasure"> <small v-bind:class="{ 'text-danger': product['discount'] !== null }"> /
                            {{ $t(getMeasure(product['measure'])) }}</small></template>
                    <template v-if="withQuantity"> <span class="text-muted"> x {{ product['quantity'] }}</span></template>
                </template>
                <template v-else>
                    <span v-if="product.price_from">{{ $t('From') }} </span>{{ priceFull(product['price']) }}.<small>{{
                        priceDivision(product['price']) }}</small> ₴ <template v-if="withMeasure"> <small
                            v-bind:class="{ 'text-danger': product['discount'] !== null }"> / {{
                                $t(getMeasure(product['measure'])) }}</small></template>
                </template>
            </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
        withMeasure: {
            type: Boolean,
            default: false
        },
        withQuantity: {
            type: Boolean,
            default: false
        }
    },
    created() { },
    methods: {
        getMeasure(measure) {
            switch (measure) {
                case 'square_meter':
                    return 'm_square_meter';
                case 'thing':
                    return 'm_thing';
                case 'roll':
                    return 'm_roll';
                case 'packaging':
                    return 'm_packaging';
                default:
                    return 'm_thing';
            }
        }
    }
}
</script>
