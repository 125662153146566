import uk from "vee-validate/dist/locale/uk.json";

export default {
    fields: {
        'email': "E-mail",
        'Email': 'Електронний лист',
        'password': "Пароль",
        'phone': 'Номер телефону',
        'first_name': 'Iм\'я',
        'deliveryCity': 'Населений пункт',
        'deliveryWarehouses': 'Відділення',
        'deliveryStreet': 'Вулиця',
        'username': 'Ім\'я користувача',
        'review': 'Перегляд',
        'last_name': 'Прізвище',
        'password_confirmation': 'Підтвердження пароля',
        'shippingCity': 'Населений пункт',
        'shippingWarehouses': 'Відділення',
        'shippingStreet': 'Вулиця',
        'subject': 'Тема',
        'message': 'Повідомлення',
        'required_with': 'Обов\'язково до заповнення'
    },
    validation: uk.messages,
    'Service': 'Cервіс',
    'Shops': 'Магазини',
    'Stocks': 'Акції',
    'Payment and delivery': 'Доставка і оплата',
    'Wholesalers': 'Оптовикам',
    'My account': 'Мій аккаунт',
    'Login': 'Вхід',
    "I'm looking for...": 'Я шукаю...',
    'mo-fr': 'пн-пт',
    'sa-su': 'сб-нд',
    'mo-sa': 'пн-сб',
    'su': 'нд',
    'Got Question? Call us!': 'Є питання? Телефонуйте нам!',
    'Information': 'Iнформація',
    'Customer Service': 'Обслуговування',
    'Get In Tuch': 'Будемо на зв\'язку',
    'Login to account': 'Увійти в акаунт',
    'Please register in order to checkout more quickly': 'Зареєструйтесь, щоб швидше Оформити замовлення',
    'Your Email': 'Ваш Email',
    'Your Password': 'Ваш Пароль',
    'Lost your password?': 'Забули пароль?',
    'Remember me': 'Запам\'ятайте мене',
    'Sign Up for Newsletter': 'Підписатися на розсилку',
    'Register': 'Реєстрація',
    'Confirm Password': 'Підтвердіть пароль',
    'Your Name': 'Ваше ім\'я',
    'Please enter your email to recovery password': 'Будь ласка, введіть адресу електронної пошти для відновлення пароля',
    'About Us': 'Про нас',
    'Checkout': 'Замовлення',
    'Logout': 'Вийти',
    'CATALOG': 'КАТАЛОГ',
    'View Cart': 'Переглянути кошик',
    'Total': 'Всього',
    'This page cannot be founded': 'Ця сторінка не може бути знайдена',
    'Oops! The page you are looking for does not exist. It might have been moved or deleted.': 'На жаль! Сторінка, яку ви шукаєте, не існує. Можливо, вона була переміщена або видалена.',
    'Go Homepage': 'Перейти на головну сторінку',
    'Contact Us': 'Зв\'язатися з нами',
    'Help': 'Допомога',
    'Terms & Conditions': 'Умови використання',
    'Returns': 'Повернення товару',
    'Shipping': 'Доставка',
    'Privacy Policy': 'Політика конфіденційності',
    'Money-back': 'Повернення грошей',
    'Payment Methods': 'Способи оплати',
    'Credit': 'Кредит',
    'From cheap to expensive': 'Від дешевих до дорогих',
    'From expensive to cheap': 'Від дорогих до дешевих',
    'Popular': 'Популярні',
    'Novelty': 'Новинки',
    'Promotional': 'Акційні',
    'By rating': 'За рейтингом',
    'Show': 'Показувати',
    'Sort By': 'Сортувати за',
    'Subscribe to our newsletter and get the latest information first': 'Підпишись на наші новини і отримуй актуальну інформацію першим',
    // 'Subscribe': 'Подписаться',
    'Your email address': 'Ваша електронна пошта',
    'All Rights Reserved.': 'Всі права захищені.',
    'Get in touch': 'Зв\'язатися',
    'Write Us A Message': 'Напишіть нам повідомлення',
    'Your Subject': 'Тема',
    'Your Phone': 'Ваш телефон',
    'Your message': 'Ваше повідомлення',
    'The message was sent successfully!!!': 'Спасибі, ми отримали Ваше повідомлення і незабаром Вам відповімо !!!',
    'Call us Now': 'Зателефонуйте нам зараз',
    'Email': 'Електронний лист',
    'Our Address': 'Наша адреса',
    'Newsletter': 'Розсилка',
    'Best Price': 'Найкраща ціна',
    'Guaranteed price': 'Гарантована ціна',
    'Free shipping': 'Безкоштовна доставка',
    'Orders over 5000 UAH': 'Замовлення понад 5000 ₴',
    'Sucure Payment': 'Безпечна оплата',
    '100% secure payment': '100% безпечний платіж',
    'Free Return': 'Безкоштовне повернення',
    'Within 30 days returns': 'Протягом 30 днів',
    'Home': 'Компанія',
    'View': 'Перегляд',
    'Items': 'Товар(и)',
    'PRODUCT': 'ТОВАР',
    'Products catalog': 'Каталог товарів',
    'NAME': 'ІМ\'Я',
    'UNIT PRICE': 'ЦІНА ЗА ОДИНИЦЮ',
    'QUANTITY': 'КІЛЬКІСТЬ',
    'TOTAL': 'ЗАГАЛОМ',
    'Continue shopping': 'Продовжити покупки',
    'You Pay': 'До оплати',
    'Apply': 'Застосувати',
    'Enter Your Coupon': 'Введіть свій купон',
    'search': 'пошук',
    'search?': 'Що шукаєте?',
    'Contact data': 'Контактні дані',
    'Faq': 'Питання та відповіді',
    'Sign in': 'Увійти',
    'Sign up': 'Зареєструватися',
    'Email address': 'Адреса електронної пошти',
    'Email Address': 'Адреса електронної пошти',
    'Forgot password?': 'Забули пароль?',
    'Hello': 'Вітаю',
    'My Account': 'Мій аккаунт',
    'Wishlist': 'Бажання',
    'Orders History': 'Історія замовлень',
    'Profile settings': 'Налаштування профілю',
    'We can\'t seem to find the page you are looking for.': 'Ми не можемо знайти сторінку, яку ви шукаєте.',
    'Homepage': 'Домашня сторінка',
    'Return to homepage': 'Повернутися на головну сторінку',
    'Search': 'Пошук',
    'Here are some helpful links instead': 'Ось деякі корисні посилання',
    'Find with advanced search': 'Знайти з розширеним пошуком',
    'Help & Support': 'Допомога і Підтримка',
    'Visit our help center': 'Відвідайте наш довідковий центр',
    'Fast and high quality delivery': 'Швидка і якісна доставка',
    'Free delivery for all orders over $200': 'Безкоштовна доставка для всіх замовлень понад 200 доларів',
    'Money back guarantee': 'Гарантія повернення грошей',
    'We return money within 30 days': 'Ми повертаємо гроші протягом 30 днів',
    '24/7 customer support': '24/7 служба підтримки',
    'Friendly 24/7 customer support': 'Дружня служба підтримки 24/7',
    'Secure online payment': 'Безпечний онлайн-платіж',
    'We possess SSL / Secure сertificate': 'У нас є SSL / Безпечний сертифікат',
    'Stay informed': 'Будь в курсі',
    'sub_info': 'Підпишіться на нашу розсилку, щоб отримувати ранні знижки, поновлення і інформацію про нові продукти.',
    'Subscribe': 'Підписатся',
    'My Cart': 'Мій кошик',
    'Catalogs': 'Каталог',
    'Account & shipping info': 'Обліковий запис і доставка',
    'Your account': 'Ваш аккаунт',
    'Shop': 'Магазин',
    'Services': 'Послуги що надаються',
    'Promotions': 'Акції',
    'Contacts': 'Контакти',
    'Help center': 'Центр допомоги',
    'Address': 'Адреса',
    'prod_catalog': 'Каталог товарів',
    'Compare': 'Порівняння',
    'Categories': 'Категорії',
    'Shop catalogs': 'Каталог',
    'Popular brands': 'Популярні бренди',
    'View all': 'Дивитися все',
    'Product Catalog': 'Каталог товарів',
    'Sort by': 'Сортувати',
    'Popularity': 'Популярні',
    'Low - Hight Price': 'Низька ціна',
    'High - Low Price': 'Висока ціна',
    'Price': 'Ціна',
    'of': 'з',
    'products': 'товарів',
    'Main store address': 'Адреса магазину',
    'Working hours': 'Робочий час',
    'Phone numbers': 'Телефонні номери',
    'Phone Number': 'Номер телефону',
    'New Password': 'Новий пароль',
    'Update profile': 'Оновити профіль',
    'Subscribe me to Newsletter': 'Підписатися на розсилку новин',
    'Email addresses': 'Адреси електронної пошти',
    'Drop us a line': 'Напишіть нам',
    'Your name': 'Ваше ім\'я',
    'Your phone': 'Ваш телефон',
    'Subject': 'Тема',
    'Message': 'Повідомлення',
    'Partner outlet stores': 'Партнерські магазини',
    'Our stores': 'Наші магазини',
    'Delivery info': 'Iнформація про доставку',
    'Order tracking': 'Відстеження замовлення',
    'Refunds & replacements': 'Повернення і заміна',
    'About us': 'Про нас',
    'About company': 'Про компанію',
    'News': 'Новини',
    'Outlets': 'Магазини',
    'Affiliates': 'Філії',
    'Support': 'Служба підтримки',
    'Privacy': 'Конфіденційність',
    'Terms of use': 'Умови експлуатації',
    'My orders': 'Мої замовлення',
    'Account': 'Профіль',
    'Orders history': 'Історія замовлень',
    'Sort orders': 'Сортувати замовлення',
    'Sign out': 'Вихід',
    'Dashboard': 'Панель управління',
    'Account settings': 'Налаштування облікового запису',
    'All': 'Усе',
    'Delivered': 'Доставлені',
    'In Progress': 'В ході виконання',
    'Canceled': 'Скасовано',
    'Orders': 'Замовлення',
    'Profile info': 'Інформація про профіль',
    'Addresses': 'Адреси',
    'How can we help?': 'Як ми можемо допомогти?',
    'Ask Questions. Browse Topics. Find Answers.': 'Задавати питання. Перегляд тем. Знайти відповіді.',
    'Ask your question': 'Задайте ваше запитання',
    'Suggestions': 'Пропозиції',
    'Select a topic': 'Виберіть тему',
    'F.A.Q.': 'ПОШИРЕНІ ЗАПИТАННЯ.',
    'Haven\'t found the answer? We can help.': 'Не знайшли відповідь? Ми можемо допомогти.',
    'Contact us and we’ll get back to you as soon as possible.': 'Напишіть нам, і ми зв\'яжемося з вами як можна швидше.',
    'Submit a request': 'Відправити запит',
    'Russian': 'Русский',
    'Ukrainian': 'Український',
    'Your email': 'Ваш email',
    'Order traking': 'Відстеження замовлення',
    'Useful links': 'Корисні посилання',
    'Password': 'Пароль',
    'Confirm password': 'Підтвердіть пароль',
    'First name': 'Ім\'я',
    'Last name': 'Прізвище',
    'First Name': 'Ім\'я',
    'Last Name': 'Прізвище',
    'For customers': 'Для клієнтів',
    'For support': 'Підтримка',
    'Click to see map': 'Натисніть, щоб побачити карту',
    'Send message': 'Надіслати повідомлення',
    'Provide short title of your request': 'Коротка назва запиту',
    'Please describe in detail your request': 'Будь ласка, опишіть детально ваш запит',
    'Petrov Petr': 'Петров Петро',
    'Learn more': 'Дізнатися більше',
    'Forgot your password?': 'Забули Ваш пароль?',
    'Change your password in three easy steps. This helps to keep your new password secure.': 'Змініть свій пароль в три простих кроки. Це допомагає зберегти ваш новий пароль в безпеці.',
    'Fill in your email address below.': 'Заповніть вашу адресу електронної пошти нижче.',
    'We\'ll email you a temporary code.': 'Ми надішлемо вам тимчасовий код.',
    'Use the code to change your password on our secure website.': 'Використовуйте код для зміни пароля на нашому веб-сайті.',
    'Enter your email address': 'Введіть Вашу адресу електронної пошти',
    'Get new password': 'Отримати новий пароль',
    'Password recovery request completed successfully.':'Запит на відновлення пароля успішно виконаний.',
    'Success': 'Успішно',
    'Add to Cart': 'Додати в кошик',
    'View details': 'Подивитися деталі',
    'Add to wishlist': 'Додати в список бажань',
    'Add to Wishlist': 'Додати в список бажань',
    'Newest': 'Новітні',
    'Oldest': 'Старі',
    'Load more reviews': 'Завантажити більше відгуків',
    'Submit a Review': 'Відправити відгук',
    'High rating': 'Високий рейтинг',
    'Low rating': 'Низький рейтинг',
    'Your name and surname': 'Ваше ім\'я та прізвище',
    'To add an item to your wishlist, you need to login.': 'Авторизуйтесь для додавання товару в бажані.',
    'Warning': 'Попередження',
    'Subtotal': 'Загалом',
    'Discount': 'Знижка',
    // 'You may also like': 'Вам также может понравиться',
    'Expand cart': 'Кошик',
    'Back to Cart': 'Повернутися в кошик',
    'Proceed to Shipping': 'Перейти до доставки',
    'Quantity': 'Кількість',
    'Back': 'Назад',
    'Next': 'Наступний',
    'Order summary': 'Підсумок замовлення',
    'Shipping address': 'Адреса доставки',
    'Additional comments': 'Додаткові коментарі',
    'Note': 'Примітка',
    'Apply promo code': 'Застосувати промо-код',
    'Promo code': 'Промо-код',
    'Proceed to Checkout': 'Перейти до оформлення',
    'Your cart': 'Ваша кошик',
    'Your details': 'Ваші дані',
    'Payment': 'Оплата',
    'Review': 'Відгук',
    'Products': 'Товари',
    'Cart': 'Кошик',
    'Remove': 'Вилучити',
    'Update cart': 'Оновити кошик',
    'Search for products': 'Пошук товарів',
    'Product comparison': 'Порівняння товарів',
    'Сomparison': 'Порівняння',
    'My wishlist': 'Мій список бажань',
    'List of items you added to wishlist': 'Список продуктів, які ви додали в список бажань',
    'My addresses': 'Мої адреси',
    'List of your registered addresses': 'Список ваших зареєстрованих адрес',
    'Actions': 'Дії',
    'Update you profile details below': 'Оновіть дані свого профілю нижче',
    'Profile saved successfully': 'Профіль успішно збережений',
    'Add new address': 'Додати нову адресу',
    'Expand menu': 'Розгорнути меню',
    'Related articles': 'Статті по темі',
    'Track order': 'Відстежити замовлення',
    'Go back shopping': 'Повернутися за покупками',
    'Thank you for your order!': 'Дякую за ваше замовлення!',
    'Your order has been placed and will be processed as soon as possible.': 'Ваше замовлення було оформлено і буде оброблено якомога швидше.',
    'Make sure you make note of your order number, which is': 'Переконайтеся, що ви записали свій номер замовлення, який',
    'You will be receiving an email shortly with confirmation of your order.': 'Незабаром ви отримаєте повідомлення електронної пошти з підтвердженням вашого замовлення.',
    'You can now': 'Ти можеш зараз',
    'Popular services': 'Популярні послуги',
    'You may also like': 'Вам також може сподобатися',
    'General Info': 'Загальна інформація',
    'Tech Specs': 'Характеристики',
    'Reviews': 'Відгуки',
    'Product available': 'Товар доступний',
    'Add to': 'Добавити в',
    'Customers recommended this product': 'Клієнти рекомендували цей продукт',
    'out of': 'з',
    'Overall rating': 'Загальний рейтинг',
    'Add a new address': 'Додати нову адресу',
    'Make this address primary': 'Зробити цю адресу основною',
    'Company': 'Компанія',
    'City': 'Місто',
    '(street / house / apartments)': '(вулиця, будинок, квартира)',
    'Close': 'Закрити',
    'Add address': 'Додати адресу',
    'Choose shipping method': 'Виберіть спосіб доставки',
    'Shipping method': 'Спосіб доставки',
    'Delivery time': 'Термін доставки',
    'Handling fee': 'Плата за обробку',
    'From the location of the store': 'З місця знаходження магазину',
    'Local Pickup from store': 'Самовивіз з магазину',
    'Local pickup from store': 'Самовивіз з магазину',
    'Courier delivery': 'Кур\'єрська доставка',
    'Individually': 'Індивідуально',
    'up to one week': 'До одного тижня',
    'Local courier shipping': 'Місцева кур\'єрська доставка',
    'days': 'днів',
    'All addresses (default zone)': 'Всі адреси',
    'Review your order': 'Перегляньте замовлення',
    'After order confirmation': 'Після підтвердження замовлення',
    'Shipping to': 'Доставка',
    'Client': 'Клієнт',
    'Phone': 'Телефон',
    'Back to Shipping': 'Повернутися до доставки',
    'Payment method': 'Спосіб оплати',
    'Complete order': 'Завершити замовлення',
    'Complete': 'Завершити',
    'Write a review': 'Написати відгук',
    'Shipping options': 'Варіанти доставки',
    'Share': 'Поділитися',
    'Will be displayed on the comment.': 'Відображатиметься в коментарі.',
    'Rating': 'Рейтинг',
    'Your review must be at least 30 characters.': 'Ваш відгук повинен містити не менше 30 символів.',
    'Pros': 'Плюси',
    'Cons': 'Мінуси',
    'For authorized users only': 'Тільки для авторизованих користувачів',
    'Product added to cart.': 'Товар додано до кошика.',
    'Your review will be added after moderation.': 'Ваш відгук буде додано після модерації.',
    'Notify me of replies by email': 'Повідомляти про відповіді по електронній пошті',
    'I recommend this product': 'Я рекомендую цей продукт',
    'Find us': 'Знайди нас',
    'Call us': 'Зателефонуйте нам',
    'Write us': 'Напишіть нам',
    'Back to Addresses': 'Повернутися до адрес',
    'Current email is used. Login to continue ordering.': 'Поточний емейл використовується. Залогінтесь для продовження оформлення замовлення.',
    'Preview': 'Попередній перегляд',
    'Enter order id to search for information.': 'Введіть ідентифікатор замовлення для пошуку інформації',
    'Shipped via': 'Доставка',
    'Status': 'Статус',
    'Expected date': 'Дата виконання',
    'First step': 'Перший крок',
    'Second step': 'Другий крок',
    'Third step': 'Третій крок',
    'Fourth step': 'Четвертий крок',
    'Order placed': 'Нове замовлення',
    'Tracking order': 'Відстеження замовлення',
    'Processing order': 'Опрацювання замовлення',
    'Order completed': 'Замовлення виконано',
    'Product dispatched': 'Товар відправлено',
    'Notify me when order is delivered.': 'Повідомити мене, коли замовлення буде доставлене.',
    'View Order Details': 'Переглянути деталі замовлення',
    'Order No': '№ замовлення',
    'Date Purchased': 'Дата купівлі',
    'Order #': '№ замовлення',
    'New': 'Новий',
    'Cancelled': 'Скасовано',
    'prod_count': 'товару(ів)',
    'Bestsellers': 'Бестселери',
    'New arrivals': 'Нові надходження',
    'Top rated': 'Найпопулярніші',
    'Limited Offer': 'Обмежена пропозиція',
    'Trending products': 'Актуальні продукти',
    'More products': 'Більше продуктів',
    'Read the blog': 'Читати блог',
    'Latest store, fashion news and trends': 'Актуальний шопінг, новини і тренди',
    'Refunds': 'Повернення',
    'Delivery': 'Доставка',
    'View offers': 'Переглянути пропозіції',
    'Shop Now': 'Придбати зараз',
    'Shop now': 'Придбати зараз',
    'View more': 'Переглянути більше',
    'Follow on Instagram': 'Дивитися в Instagram',
    'Submit request': 'Відправити запит',
    'help_info_msg': 'Наша дружна команда підтримки завжди готова допомогти вам. Почніть з вибору теми, з якої ми можемо вам допомогти.',
    'Privacy policy': 'Політика конфіденційності',
    'Request Message': 'Повідомлення запиту',
    'Request Subject': 'Тема запиту',
    'of users found this review helpful': 'користувачів вважають цей відгук корисним',
    'You have successfully subscribed to the newsletter.': 'Ви успішно підписалися на розсилку.',
    'Blog': 'Блог',
    'Blog list': 'Список блогів',
    'All rights reserved.': 'Всі права захищені.',
    'Made by': 'Зроблено',
    'Authentication only - we won\'t spam you.': 'Тільки авторизованим - ми не будемо вас спамити.',
    'Remove from wishlist': 'Видалити зі списку бажань',
    'Select product category to compare.': 'Виберіть категорію товарів для порівняння.',
    'Fast delivery': 'Швидка доставка',
    'Quality of services provided': 'Якість послуг, що надаються',
    'Wide range of goods': 'Широкий асортимент товарів',
    'Country': 'Країна',
    'Brand': 'Бренд',
    'Main': 'Головний',
    'Successfully completed': 'Успішно виконаний',
    'Are you sure to delete this product?': 'Ви впевнені, що хочете видалити цей продукт?',
    'No, Thanks': 'Ні дякую',
    'OK': 'Так',
    'The cart is empty. Please add products to continue.': 'Кошик порожній. Будь ласка, додайте продукти, щоб продовжити.',
    'Order ID': 'ID замовлення',
    '< Prev': '< Попередня',
    'Next >': 'Наступна >',
    'Menu': 'Меню',
    'Sending': 'Відправлений',
    'Sales experience': 'Досвід продажів',
    'Years of sales experience': 'Багаторічний досвід продажів',
    'High product quality': 'Висока якість продукції',
    'Product quality': 'Якість продукції',
    'Wide range of products': 'Широкий асортимент товарів',
    'Assortment of goods': 'Асортимент товарів',
    'Flexibility and individual approach to each client': 'Гнучкість і індивідуальний підхід до кожного клієнта',
    'Approach to each client': 'Підхід до кожного клієнта',
    'main_addr': 'Україна, Кропивницький, вул. Волкова, 13В',
    'Search, Select, Buy online': 'Шукати, вибирати, купувати онлайн',
    'View products': 'Переглянути товари',
    'about_us_first': 'Мережа будівельних магазинів Зодчий в місті Кропивницький надає на Ваш вибір товари від кращих світових виробників плитки, світильників, шпалер, лінолеуму, паркету, і безлічі супутніх товарів для створення інтер\'єру Вашої мрії. Менеджери-консультанти магазину Зодчий в Кропивницькому  з радістю допоможуть Вам підібрати товар на будь-який смак, а також бюджет. З докладним описом товарів Ви можете ознайомитися на нашому сайті, а ще замовити 3D проект, оформити доставку, оплатити замовлення, і отримати його найближчим часом. Ми працюємо для Вас щодня!',
    'about_us_second_title': 'Купуючи покупки онлайн в Мережі будівельних магазинів Зодчий в Кропивницькому Ви отримуєте багато переваг:',
    'about_us_second_p1': 'широкий асортимент продукції на одному сайті, що економить ваш час і сили в пошуках потрібного товару;',
    'about_us_second_p2': 'гарантовано якісна продукція;',
    'about_us_second_p3': 'доставка будматеріалів транспортом компанії або послугами кур\'єра на потрібну Вам адресу і попередньо обумовлений заздалегідь час або ж самовивезення з магазину мережі Зодчий в Кропивницькому.',
    'Guarantee': 'Гарантія',
    'Review order': 'Перегляд замовлення',
    'Info': 'Інформація',
    'Order Design': 'Замовити дизайн',
    'select file': 'виберіть файл',
    'file with a size less than 5mb': 'файл розміром менше 5 Мб',
    'send_service_request': 'Відправити запит',
    'service_request_success': 'Запит відправлений успішно.',
    'Leave a request': 'Залиште запит',
    'Collections': 'Колекції',
    'Description': 'Опис',
    'nova_poshta': 'Нова Пошта',
    'to branch': 'на відділення',
    'to_np_branch': 'На відділення Нової Пошти',
    'by the address': 'за адресою',
    'Local Pickup': 'Самовивіз',
    'Courier shipping': 'Кур\'єрська доставка',
    'enter the settlement of Ukraine': 'введіть населений пункт України',
    'Warehouse': 'Відділення',
    'Street': 'Вулиця',
    'Select location': 'Виберіть населений пункт',
    'Select warehouse': 'Виберіть відділення',
    'House': 'Дім',
    'Apartment': 'Квартира',
    'This value must be phone': 'Це значення повинно бути телефоном',
    'Shipping Type': 'Тип доставки',
    'Fill in all required fields': 'Заповніть усі необхідні поля',
    'New collection': 'Нова колекція',
    'Other collections': 'Інші колекції',
    'About collection': 'Про колекцію',
    'Here you will find products from the following categories': 'Тут ви знайдете товари з наступних категорій',
    'Too Many Attempts.': 'Багато запитів, спробуйте пізніше.',
    'Ok': 'Ок',
    'Zodchiy - Shop': 'Zodchiy - Каталог товарів',
    '< ': '< ',
    ' >': ' >',
    'Petrov': 'Петров',
    'Petr': 'Петро',
    'Password recovery': 'Відновлення паролю',
    'Catalog': 'Каталог',
    'payment_and_delivery': 'Оплата та доставка',
    'countdown_d': 'д',
    'countdown_h': 'г',
    'countdown_m': 'х',
    'countdown_s': 'с',
    'Is collection': 'Коллекція',
    'Availability': 'В наявності',
    'Collection elements': 'Елементи колекції',
    'Select collection element': 'Виберіть елемент колекції',
    'Back to collection': 'Повернутися до колекції',
    'Loading': 'Завантаження',
    'without_price': 'Ціну уточнюйте.',
    'Promotion is over': 'Акція закінчена',
    'Promotion': 'Акції',
    'Code': 'Код',
    'Until the end': 'До закінчення',
    'Found in category': 'Знайдено в категорії',
    'From': 'Від',
    'Bad recaptcha': 'Низький рівень довіри до браузера!',
    'Contact informations': 'Контактна інформація',
    'Shipping information': 'Інформація про доставку',
    'Payment information': 'Інформація про оплату',
    'online_payment': 'Оплата через Інтернет',
    'on_delivery': 'Оптала при отриманні',
    'Pay': 'Оплатити',
    'FAILED LOAD IMAGE': 'ПОМИЛКА ЗАВАНТАЖЕННЯ ЗОБРАЖЕННЯ',
    'validation.min.string': 'Вкажіть більше інформації в повідомленні',
    'No promotions available': 'Немає доступних акцій',
    'm_square_meter': 'кв.м',
    'm_thing': 'шт.',
    'm_roll': 'рулон',
    'm_packaging': 'упаковка'
    // 'Payment and delivery': 'Оплата і доставка'
    // 'Terms of use': 'Умови користування',

};