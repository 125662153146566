// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        getList ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/services').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getBestServices ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/services/best').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/services/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };