// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
        ordersList: JSON.parse(localStorage.getItem('ordersList')) ?? [],
    },
    getters: {
        ordersList: state => state.ordersList,
        ordersSubTotal: state => state.ordersList ? state.ordersList.length : 0
    },
    mutations: {
        SET_ORDERS (state, data) {
            state.ordersList = data
            localStorage.setItem('ordersList', JSON.stringify(data))
        },
        REMOVE_ORDERS (state, data) {
            state.ordersList = []
            localStorage.removeItem('ordersList')
        }
    },
    actions: {
        getList ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/orders').then((response) => {
                    commit('SET_ORDERS', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/orders/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getByNumber ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/orders/by_number/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/orders', data).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        createUnAuth ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/orders/create', data).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/orders/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };