// import axios from 'axios'
import Vue from 'vue';
import { HTTP, axiosBasic } from '@/api/http_utils'
import axios from 'axios'
import router from '@/router/index.js'

export default {
    namespaced: true,
    state: {
        apiKey: localStorage.getItem('api_key'),
    },
    getters: {
        isAuthenticated: state => !!state.apiKey,
        apiKey: state => state.apiKey,
    },
    mutations: {
        AUTH (state, authData) {
            if (authData.apiKey) {
                state.apiKey = authData.apiKey;
                localStorage.setItem('api_key', authData.apiKey);

                state.tokenType = authData.tokenType;
                localStorage.setItem('token_type', authData.tokenType);
              } else {
                localStorage.removeItem('api_key');
                localStorage.removeItem('token_type');
                state.isAuthenticated = false;
              }
            // localStorage.setItem('api_key', authData.apiKey);
            // state.apiKey = authData.apiKey;
            // state.tokenType = authData.tokenType;
        },
        AUTH_LOGOUT (state) {
            localStorage.removeItem('api_key');
            localStorage.removeItem('profile');
            localStorage.removeItem('token_type');
            state.isAuthenticated = false;
            state.apiKey = false;
            // console.log('log out')
        }
    },
    actions: {
        registerUser ({ commit }, registrationData) {
            return new Promise((resolve, reject) => {
                HTTP.post('/register', registrationData).then(() => {
                    let clientData = {
                        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
                        grant_type: 'password',
                        scope: '*'
                    }
                    HTTP.post('oauth/token', { username: registrationData.email, password: registrationData.password, ...clientData }).then(response => {
                        axios.defaults.headers.common['Content-Type'] = 'application/json'
                        if (response) {
                            axios.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                            axiosBasic.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                            // axiosCachedImage.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                        }
                        commit('AUTH', { apiKey: response['access_token'], tokenType: response['token_type'] })
                        resolve(response)
                    }).catch(error => {
                        this.dispatch('getErrors', error.response.data).then()
                        commit('AUTH_LOGOUT')
                        reject(error)
                    })
                }).catch(error => {
                    this.dispatch('getErrors', error.response.data).then()
                    commit('AUTH_LOGOUT')
                    reject(error)
                })
            })
        },
        authUser ({ commit }, AuthData) {
            return new Promise((resolve, reject) => {
                const clientData = {
                    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                    client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
                    grant_type: AuthData['grant_type'],
                    scope: '*'
                }
                HTTP.post('oauth/token', { ...AuthData, ...clientData }).then(response => {
                    axios.defaults.headers.common['Content-Type'] = 'application/json'
                    if (response) {
                        axios.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                        axiosBasic.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                        // axiosCachedImage.defaults.headers.common['Authorization'] = response['token_type'] + ' ' + response['access_token']
                    }
                    commit('AUTH', { apiKey: response['access_token'], tokenType: response['token_type'] })
                    resolve(response)
                }).catch(error => {
                    let err = { errors: {apiErrors: [error.response.data] }}
                    this.dispatch('getErrors', err).then()
                    commit('AUTH_LOGOUT')
                    reject(error)
                })
            })
        },
        logOut ({ commit }) {
            sessionStorage.clear()
            commit('AUTH_LOGOUT')
            // router.push({'name': 'home'})
            // location.reload()
        }
    //   setLanguage({ commit }, languages) {
    //     if (typeof languages === 'string') {
    //       commit('SET_LANGUAGE', languages);
    //     } else {
    //       const language = supportedLanguages.find(sl =>
    //         languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)));
    //         commit('SET_LANGUAGE', language);
    //     }
    //   },
    },
  };