<template>
  <footer class="bg-dark pt-5">
    <div class="container">
      <div>
        <!-- <div class="row pb-3">
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="czi-message text-primary" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">{{ $t('Approach to each client') }}</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">{{ $t('Flexibility and individual approach to each client') }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="czi-filter-alt text-primary" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">{{ $t('Assortment of goods') }}</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">{{ $t('Wide range of products') }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="czi-security-check text-primary" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">{{ $t('Product quality') }}</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">{{ $t('High product quality') }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="czi-rocket text-primary" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">{{ $t('Sales experience') }}</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">{{ $t('Years of sales experience') }}</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <hr class="hr-light pb-4 mb-3"> -->
      <div class="row pb-2">
        <div class="col-md-3 col-sm-3">
          <div class="widget widget-links widget-light pb-2 mb-4">
            <h3 class="widget-title text-light">{{ $t('Account & shipping info') }}</h3>
            <ul class="widget-list">
              <li class="widget-list-item">
                <template v-if="isAuth">
                  <router-link :to="{ name: 'AccountProfileInfo' }" class="widget-list-link">
                    {{ $t('Your account') }}
                  </router-link>
                </template>
                <template v-else>
                  <a @click="openSignInModal" class="widget-list-link" type="button" id="#" data-toggle="modal" data-target="#SignInModal">
                  {{ $t('Your account') }}
                  </a>
                </template>
                <!-- <a class="widget-list-link" href="#">{{ $t('Your account') }}</a> -->
              </li>
              <!-- <li class="widget-list-item"><a class="widget-list-link" href="#">{{ $t('Refunds & replacements') }}</a></li> -->
              <li class="widget-list-item">
                <router-link :to="{ name: 'OrderTracking' }" class="widget-list-link">
                  {{ $t('Order tracking') }}
                </router-link>
              </li>
              <li class="widget-list-item">
                <router-link :to="{ name: 'PaymentAndDelivery' }" class="widget-list-link">
                  {{ $t('Payment and delivery') }}
                </router-link>
              </li>
              <li class="widget-list-item">
                <router-link :to="{ name: 'Guarantee' }" class="widget-list-link">
                  {{ $t('Guarantee') }}
                </router-link>
              </li>
              <!-- <li class="widget-list-item"><a class="widget-list-link" href="#">{{ $t('Taxes & fees') }}</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-3">
          <div class="widget widget-links widget-light pb-2 mb-4">
            <h3 class="widget-title text-light">{{ $t('About us') }}</h3>
            <ul class="widget-list">
              <li class="widget-list-item">
                <router-link :to="{ name: 'AboutUs' }" class="widget-list-link">
                  {{ $t('About company') }}
                </router-link>
              </li>
              <!-- <li class="widget-list-item"><a class="widget-list-link" href="#">Our team</a></li>
              <li class="widget-list-item"><a class="widget-list-link" href="#">Careers</a></li> -->
              <li class="widget-list-item">
                <router-link :to="{ name: 'BlogList' }" class="widget-list-link">
                  {{ $t('News') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="widget pb-2 mb-4">
            <h3 class="widget-title text-light pb-1">{{ $t('Stay informed') }}</h3>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="validate" action="https://studio.us12.list-manage.com/subscribe/post-json?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126&amp;c=?" method="get" name="mc-embedded-subscribe-form" id="mc-embedded-subscribe-form">
              <div class="input-group input-group-overlay flex-nowrap">
                <div class="input-group-prepend-overlay"><span class="input-group-text text-muted font-size-base"><i class="czi-mail"></i></span></div>
                <ValidationProvider vid="email" name="email" rules="required|email" v-slot="{  }">
                  <input class="form-control prepended-form-control" v-model="email" type="email" name="EMAIL" id="mce-EMAIL" value="" :placeholder="$t('Your email')" required>
                </ValidationProvider>
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit" name="subscribe" id="mc-embedded-subscribe">{{ $t('Subscribe') }}*</button>
                </div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabindex="-1">
              </div><small class="form-text text-light opacity-50" id="mc-helper">*{{ $t('sub_info') }}</small>
              <div class="subscribe-status"></div>
            </form>
            </ValidationObserver>
          </div>
          <!-- <div class="widget pb-2 mb-4">
            <h3 class="widget-title text-light pb-1">Download our app</h3>
            <div class="d-flex flex-wrap">
              <div class="mr-2 mb-2"><a class="btn-market btn-apple" href="#" role="button"><span class="btn-market-subtitle">Download on the</span><span class="btn-market-title">App Store</span></a></div>
              <div class="mb-2"><a class="btn-market btn-google" href="#" role="button"><span class="btn-market-subtitle">Download on the</span><span class="btn-market-title">Google Play</span></a></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="pt-5 bg-darker">
      <div class="container">
        <div class="row pb-2">
          <div class="col-md-8 text-center text-md-left mb-4">
            <div class="text-nowrap mb-4">
              <template v-if="$route.name === 'Home'">
                <a href="#" style="min-width: 7rem;" class="d-inline-block align-middle mt-n1 mr-3">
                  <img class="d-block" width="117" src="@/assets/img/logo.png" alt="Cartzilla"/>
                </a>
              </template>
              <template v-else>
                <router-link :to="{ name: 'Home' }" class="d-inline-block align-middle mt-n1 mr-3">
                  <img class="d-block" width="117" src="@/assets/img/logo.png" alt="Cartzilla"/>
                </router-link>
              </template>
            </div>
            <div class="widget widget-links widget-light">
              <ul class="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                <li class="widget-list-item mr-4">
                  <router-link :to="{ name: 'Contacts' }" class="widget-list-link" style="font-size: 12px !important">
                    {{ $t('Outlets') }}
                  </router-link>
                </li>
                <li class="widget-list-item mr-4">
                  <router-link :to="{ name: 'PrivacyPolicy' }" class="widget-list-link" style="font-size: 12px !important">
                    {{ $t('Privacy Policy') }}
                  </router-link>
                </li>
                <li class="widget-list-item mr-4">
                  <router-link :to="{ name: 'TermsOfUse' }" class="widget-list-link" style="font-size: 12px !important">
                    {{ $t('Terms of use') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 text-center text-md-right mb-4">
            <div class="mb-3">
              <template v-for="(social, index) in socialsList">
                <a :key="index" :class="'social-btn sb-light sb-' + social['type'] + ' ml-2 mb-2'" :href="social['url']"><i :class="'czi-' + social['type']"></i></a>
              </template>
            </div>
            <!-- <img class="d-inline-block" width="187" src="img/cards-alt.png" alt="Payment methods"/> -->
          </div>
        </div>
        <div class="pb-4 font-size-xs text-light opacity-50 text-center text-md-left">© {{ moment().format('YYYY') }} {{ $t('All rights reserved.') }} {{ $t('Made by') }} <a class="text-light" href="#" target="_blank" rel="noopener">Alex Cema</a></div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      email: '',
      socials: [],
      catalogs: []
    }
  },
  computed: {
    ...mapGetters({
      socialsList: 'socialsList',
      catalogsList: 'catalog/catalogs',
    })
  },
  watch: {
    socialsList: function () {
      this.socials = this.socialsList
    },
  },
  created () {
    this.getSocial()
  },
  methods: {
    openSignInModal: function () {
      this.$parent.$emit('openSignInModal')
    },
    onSubmit() {
      let data = {
          email: this.email
      }
      this.$store.dispatch('customerService/newsletterSubscribe', data).then(() => {
          this.email = ''
          this.$nextTick(() => this.$refs.form.reset())
          this.$notify({
              title: this.$i18n.t('Success'),
              message: this.$i18n.t('You have successfully subscribed to the newsletter.'),
              type: 'success'
          })
      }).catch(() => {
          // console.log(this.$apiErrors
          if (this.$apiErrors) {
              this.$refs.form.setErrors(this.$apiErrors)
          }
      })
    },
    getSocial () {
      this.$store.dispatch('getSocial').then((response) => {
          this.socials = response
      }).catch(() => {
          //
      })
    }
  }
}
</script>