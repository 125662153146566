// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'
import index from '@/store/index'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        contactUs ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/contacts', data).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        newsletterSubscribe ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/newsletter', data).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        newsletterUnSubscribe ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.delete('/newsletter', data).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };