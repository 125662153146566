import Vue from 'vue';
import { HTTP } from '@/api/http_utils'

export default {
    namespaced: true,
    state: {
        comparisonList: [],
        comparisonCount: 0
    },
    getters: {
        comparisonCount: state => (state.comparisonList) ? state.comparisonList.length : 0,
        comparisonList: state => state.comparisonList
    },
    mutations: {
        ADD_TO_COMPARISON (state, data) {
            state.comparisonList = data
        }
    },
    actions: {
        add ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/comparison', data).then((response) => {
                    commit('ADD_TO_COMPARISON', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateQuantity ({ commit }, [productId, quantity]) {
            return new Promise((resolve, reject) => {
                HTTP.patch(`/comparison/${productId}`, { quantity: quantity }).then((response) => {
                    commit('ADD_TO_COMPARISON', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeItem ({ commit }, productId) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/comparison/${productId}`).then((response) => {
                    commit('ADD_TO_COMPARISON', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        clear ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/comparison-clear`).then((response) => {
                    commit('ADD_TO_COMPARISON', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/comparison').then((response) => {
                    commit('ADD_TO_COMPARISON', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };