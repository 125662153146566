// import axios from 'axios'
import Vue from 'vue';
import { HTTP } from '@/api/http_utils'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        checkoutStep: localStorage.getItem('checkoutStep') ?? 1,
        checkoutData: JSON.parse(localStorage.getItem('checkoutData')) ?? {}
    },
    getters: {
        checkoutStep: state => state.checkoutStep,
        checkoutData: state => state.checkoutData
    },
    mutations: {
        SET_STEP (state, step) {
            if (step > 4) {
                step = 4
            }
            state.checkoutStep = step
            localStorage.setItem('checkoutStep', step)
        },
        SET_CHECKOUT_DATA (state, data) {
            state.checkoutData = data
            localStorage.setItem('checkoutData', JSON.stringify(state.checkoutData))
        },
        REMOVE_CHECKOUT_DATA (state) {
            state.checkoutData = []
            localStorage.removeItem('checkoutData')
        },
    },
    actions: {
        setStep ({ commit }, step) {
            return new Promise((resolve, reject) => {
                commit('SET_STEP', step)
                resolve(step)
            })
        },
        setCheckoutData ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('SET_CHECKOUT_DATA', data)
                resolve(data)
            })
        },
        add ({ commit }, [product, quantity]) {
            return new Promise((resolve, reject) => {
                commit('ADD_TO_CART', [product, quantity])
                resolve(product)
            })
        },
        updateQuantity ({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('UPDATE_QUANTITY', data)
                resolve(data)
            })
        },
        remove ({ commit }) {
            return new Promise((resolve, reject) => {
                commit('REMOVE_CHECKOUT_DATA')
            })
        },
        get ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/carts').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        wayForPay ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('https://secure.wayforpay.com/pay', data).then((response) => {
                    window.location = 'https://secure.wayforpay.com/pay'; // full URI to redirect to
                }).catch(error => {
                    reject(error)
                })
                // HTTP.post('/carts').then((response) => {
                //     resolve(response)
                // }).catch(error => {
                //     reject(error)
                // })
            })
        },
        // getList ({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get('/wishlists').then((response) => {
        //             commit('SET_WISHLISTS', response)
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // create ({ commit }, data) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.post('/wishlists', data).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // delete ({ commit }, Id) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.delete('/wishlists/' + Id).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // }
    },
  };