// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP } from '@/api/http_utils'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        getList ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/collections').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        other ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/collections/other-collections').then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // getBestServices ({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get('/services/best').then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/collections/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
  };