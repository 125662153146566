<template>
    <div class="cz-handheld-toolbar">
      <div class="d-table table-fixed w-100">
        <!-- <a class="d-table-cell cz-handheld-toolbar-item" href="#shop-sidebar" data-toggle="sidebar">
          <span class="cz-handheld-toolbar-icon"><i class="czi-filter-alt"></i></span>
          <span class="cz-handheld-toolbar-label">Filters</span>
        </a> -->
        <template v-if="isAuth">
          <router-link :to="{ name: 'AccountWishlist' }" class="d-table-cell cz-handheld-toolbar-item">
            <span class="cz-handheld-toolbar-icon"><i class="czi-heart"></i></span>
            <span class="cz-handheld-toolbar-label">{{ $t('Wishlist') }}</span>
          </router-link>
        </template>
        <template v-else>
          <a @click="openSignInModal" class="d-table-cell cz-handheld-toolbar-item" id="#" data-toggle="modal" data-target="#SignInModal">
            <span class="cz-handheld-toolbar-icon"><i class="czi-heart"></i></span>
            <span class="cz-handheld-toolbar-label">{{ $t('Wishlist') }}</span>
          </a>
        </template>
        <a class="d-table-cell cz-handheld-toolbar-item" href="#navbarCollapse" data-toggle="collapse" onclick="window.scrollTo(0, 0)">
          <span class="cz-handheld-toolbar-icon"><i class="czi-menu"></i></span>
          <span class="cz-handheld-toolbar-label">{{ $t('Menu') }}</span>
        </a>
        <router-link :to="{ name: 'ShopCart' }" class="d-table-cell cz-handheld-toolbar-item">
          <span class="cz-handheld-toolbar-icon"><i class="czi-cart"></i>
          <span class="badge badge-primary badge-pill ml-1">{{ cartProducts.length }}</span></span>
          <span class="cz-handheld-toolbar-label">{{ cartSubTotal.toFixed(2) }} ₴</span>
        </router-link>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    openSignInModal: function () {
      this.$parent.$emit('openSignInModal')
    },
  },
  computed: {
    ...mapGetters({
      cartProducts: 'cart/cartProducts',
      cartSubTotal: 'cart/cartSubTotal',
    })
  },
}
</script>