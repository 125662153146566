import { extend, configure } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { i18n } from "@/plugins/i18n";
import PhoneNumber from 'awesome-phonenumber'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend("phone", {
  // params: ["otherValue", "maxDifference"],
  // validate: (value, { otherValue, maxDifference }) => {
  validate: (value) => {
    let phone = new PhoneNumber('+' + value)
    // console.log(phone)
    if (phone.isValid()) {
      return true;
    }
    return false;
  },
  message:
    i18n.t("This value must be phone")
});

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: messages[rule] // assign message
  });
});
