// import axios from 'axios'
import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import { HTTP, axiosBasic } from '@/api/http_utils'
import axios from 'axios'
import router from '@/router/index.js'

Vue.use(VueLocalStorage);

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        getList ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products?catalog_id=${payload['catalog_id']}&category_id=${payload['category_id']}&sort_by=${payload['sort_by']}&page=${payload['page']}` + payload['filters'] ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/products/' + Id).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        search ({ commit }, searchString) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products?search=${searchString}&search_with_category=1`).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getByIds ({ commit }, Ids) {
            return new Promise((resolve, reject) => {
                HTTP.get('/products' + Ids).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getForHome ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products/home` ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getRecently ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products/recently?product_id=${Id}` ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getProductReveiws ({ commit }, Id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/products/${Id}/reviews` ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        addProductReview ({ commit }, [productId, data]) {
            return new Promise((resolve, reject) => {
                HTTP.patch(`/products/${productId}/reviews/`, data ).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
  };