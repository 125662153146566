<template>
  <header class="box-shadow-sm">
    <!-- Topbar-->
    <div class="topbar topbar-dark bg-dark">
      <div class="container">
        <div class="topbar-text dropdown d-md-none"><a class="topbar-link dropdown-toggle" href="#" data-toggle="dropdown">{{ $t('Useful links') }}</a>
          <ul class="dropdown-menu">
            <li v-for="(phone, index) in settingsPhones" v-bind:key="index"><a class="dropdown-item" :href="'tel:' + phone">
              <i class="czi-support text-muted mr-2"></i>{{ phone  }}</a>
            </li>
            <!-- <li><a class="dropdown-item" href="tel:0506753517"><i class="czi-support text-muted mr-2"></i>050 675-35-17</a></li> -->
            <li>
              <router-link :to="{ name: 'OrderTracking' }" class="dropdown-item">
                <i class="czi-location mr-2"></i>{{ $t('Order tracking') }}
              </router-link>
            </li>
            <li v-if="settingsWorkTimes">
              <a class="dropdown-item" href="#">
                <i class="czi-support text-muted mr-2"></i>{{ settingsWorkTimes.join(', ') }}</a>
            </li>
          </ul>
        </div>
        <div class="topbar-text text-nowrap d-none d-md-inline-block">
          <i class="czi-support"></i>
          <template v-for="(phone, index) in settingsPhones">
            <a class="topbar-link pr-2" :href="'tel:' + phone" v-bind:key="index">{{ phone }}</a>
          </template>
          <!-- , <a class="topbar-link" href="tel:0506753517"> 050 675-35-17</a> -->
        </div>
        <!-- <div class="cz-carousel cz-controls-static d-none d-md-block">
          <tiny-slider autoplay :autoplay-timeout="15000" :autoplay-button-output="false" :speed="700" mode="gallery" :nav="false" :controls-text="['<i class=\'czi-arrow-left\'></i>', '<i class=\'czi-arrow-right\'></i>']">
              <div class="topbar-text">{{ $t('Wide range of goods') }}</div>
              <div class="topbar-text">{{ $t('Fast and high quality delivery') }}</div>
              <div class="topbar-text">{{ $t('Quality of services provided') }}</div>
          </tiny-slider>
        </div> -->
        <div class="ml-3 text-nowrap">
          <router-link :to="{ name: 'OrderTracking' }" class="topbar-link mr-4 d-none d-md-inline-block">
            <i class="czi-location mr-2"></i>{{ $t('Order tracking') }}
          </router-link>
        </div>
      </div>
    </div>

    <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
    <div v-bind:class="{ 'navbar-stuck': isSticky }" class="navbar-sticky bg-light">
      <div class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <template v-if="$route.name === 'Home'">
            <a href="#" style="min-width: 7rem;" class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0">
              <img width="110" src="@/assets/img/logo1.png" alt="Zodchiy"/>
            </a>
          </template>
          <template v-else>
            <router-link :to="{ name: 'Home' }" style="min-width: 7rem;" class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0">
              <img width="110" src="@/assets/img/logo1.png" alt="Zodchiy"/>
            </router-link>
          </template>
          <!-- <a class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0" href="index.html" style="min-width: 7rem;">
            <img width="142" src="img/logo.png" alt="Cartzilla"/>
          </a> -->
          <router-link :to="{ name: 'Home' }" style="min-width: 4.625rem;" class="navbar-brand d-sm-none mr-2">
            <img width="74" src="@/assets/img/logo.png" alt="Zodchiy"/>
          </router-link>
          <div class="input-group-overlay d-none d-lg-flex mx-4">
            <autocomplete
              style="width: 100% !important; background-color: white !important;"
              ref="autocomplete"
              :search="search"
              :placeholder="$t('Search for products')"
              :aria-label="$t('Search for products')"
              :get-result-value="getResultValue"
              @submit="handleSubmit"
            >
             <template
              #default="{
                rootProps,
                inputProps,
                inputListeners,
                resultListProps,
                resultListListeners,
                results,
                resultProps
              }"
            >
              <div v-bind="rootProps">
                <input
                  v-bind="inputProps"
                  v-on="inputListeners"
                  :class="[
                    'autocomplete-input',
                    { 'autocomplete-input-no-results': noResults },
                    { 'autocomplete-input-focused': focused }
                  ]"
                />
                <!-- @focus="handleFocus"
                  @blur="handleBlur" -->
                <ul
                  v-if="noResults"
                  class="autocomplete-result-list"
                  style="position: absolute; z-index: 1; width: 100%; top: 100%;"
                >
                  <li class="autocomplete-result">
                    <i class="navbar-tool-icon czi-menu"></i> No results found
                  </li>
                </ul>
                <ul v-bind="resultListProps" v-on="resultListListeners">
                  <li
                    v-for="(result, index) in results"
                    :key="resultProps[index].id"
                    v-bind="resultProps[index]"
                    style="white-space: nowrap !important;"
                  >
                    <template v-if="result.catalog">
                      <i class="navbar-tool-icon czi-list" style="font-size: 1rem;"></i>
                      {{ $i18n.t('Found in category') }}:
                    </template>
                    <template v-else>
                      <i class="navbar-tool-icon czi-search"></i>
                    </template>
                      {{ result['name'] }}
                  </li>
                </ul>
              </div>
            </template>

            </autocomplete>
            <!-- <input class="form-control appended-form-control" type="text" :placeholder="$t('Search for products')"> -->
            <!-- <div class="input-group-append-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div> -->
          </div>
          <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button><a class="navbar-tool navbar-stuck-toggler" id="toggleMenu" @click='toggleMenuBtn()' type="button"><span class="navbar-tool-tooltip">{{ $t('Expand menu') }}</span>
              <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-menu"></i></div></a>
              <!-- wishlist -->
              <template v-if="isAuth">
                <router-link :to="{ name: 'AccountWishlist' }" class="navbar-tool d-none d-lg-flex">
                  <el-tooltip :visible-arrow="false" class="item" effect="dark" :content="$t('Wishlist')" placement="top">
                    <div class="navbar-tool-icon-box">
                      <span v-if="wishlistsSubTotal" class="navbar-tool-label bg-success">{{ wishlistsSubTotal }}</span>
                      <i class="navbar-tool-icon czi-heart"></i>
                    </div>
                  </el-tooltip>
                  <!-- <span class="navbar-tool-tooltip">{{ $t('Wishlist') }}</span>
                    <div class="navbar-tool-icon-box">
                      <i class="navbar-tool-icon czi-heart"></i>
                    </div> -->
                </router-link>
              </template>
              <template v-else>
                <a @click="openSignInModal" class="navbar-tool d-none d-lg-flex" type="button" id="#" data-toggle="modal" data-target="#SignInModal">
                  <span class="navbar-tool-tooltip">{{ $t('Wishlist') }}</span>
                  <div class="navbar-tool-icon-box">
                    <i class="navbar-tool-icon czi-heart"></i>
                  </div>
                </a>
              </template>
              <router-link :to="{ name: 'ProductComparison' }" class="navbar-tool d-none d-lg-flex">
                <!-- <el-tooltip :visible-arrow="false" class="item" effect="dark" :content="$t('Compare')" placement="top"> -->
                  <span class="navbar-tool-tooltip">{{ $t('Compare') }}</span>
                  <div class="navbar-tool-icon-box">
                    <span v-if="comparisonCount" class="navbar-tool-label bg-info">{{ comparisonCount }}</span>
                    <i class="navbar-tool-icon czi-compare"></i>
                  </div>
                <!-- </el-tooltip> -->
              </router-link>
              <template v-if="!isAuth">
                <a class="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" href="#" @click="openSignInModal" data-toggle="modal" data-target="#SignInModal">
                  <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-user"></i></div>
                  <div class="navbar-tool-text ml-n3"><small>{{ $t('Hello') }}, {{ $t('Sign in') }}</small>{{ $t('My Account') }}</div>
                </a>
              </template>
              <template v-else>
                <div class="dropdown navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-user"></i></div>
                  <div class="navbar-tool-text ml-n3"><small>{{ $t('Hello') }}, {{ Profile.first_name }} </small>{{ $t('My Account') }}</div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <router-link :to="{ name: 'AccountOrders' }" class="dropdown-item">
                    {{ $t('Orders History') }}
                    </router-link>
                    <router-link :to="{ name: 'AccountProfileInfo' }" class="dropdown-item">
                      {{ $t('Profile settings') }}
                    </router-link>
                    <router-link :to="{ name: 'AccountWishlist' }" class="dropdown-item">
                      {{ $t('Wishlist') }}
                    </router-link>
                  <div class="dropdown-divider"></div>
                  <a @click="logOut()" type="button" id="logout" class="dropdown-item">{{ $t('Logout') }}</a>
                  </div>
                </div>
              </template>
            <div class="navbar-tool dropdown ml-3">
              <template v-if="cartSubTotal">
                <router-link :to="{ name: 'ShopCart'}" class="navbar-tool-icon-box bg-secondary dropdown-toggle">
                  <span v-if="cartProducts.length" class="navbar-tool-label">{{ cartProducts.length }}</span><i class="navbar-tool-icon czi-cart"></i>
                </router-link>
                <router-link :to="{ name: 'ShopCart'}" class="navbar-tool-text">
                  <small>{{ $t('My Cart') }}</small>{{ cartSubTotal.toFixed(2) }} ₴
                </router-link>
              </template>
              <template v-else>
                <a id="#" class="navbar-tool-icon-box bg-secondary dropdown-toggle">
                  <span v-if="cartProducts.length" class="navbar-tool-label">{{ cartProducts.length }}</span><i class="navbar-tool-icon czi-cart"></i>
                </a>
                <a id="#" class="navbar-tool-text">
                  <small>{{ $t('My Cart') }}</small>{{ cartSubTotal.toFixed(2) }} ₴
                </a>
              </template>
              <!-- Cart dropdown-->
              <div v-if="cartProducts.length" class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                <div class="widget widget-cart px-3 pt-2 pb-3">
                  <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false">
                      <div v-for="(product, index) in cartProducts" class="widget-cart-item pb-2 border-bottom pr-1" :key="index">
                        <button class="close text-danger" type="button" aria-label="Remove" @click="removeProduct(product)"><span aria-hidden="true">&times;</span></button>
                        <div class="media align-items-center">
                          <router-link :to="{ name: 'ProductView', params: { productId: product['product_id']  }}" class="d-block mr-2">
                            <!-- <img width="64" v-if="product['main_image']" :src="mainImageUrl(product, 'product')" alt="Product"/> -->
                            <el-image
                              style="width: 64px; "
                              :src="mainImageUrl(product, 'product')"
                              :lazy="false"
                              fit="contain">
                              <div slot="error" class="image-slot">
                                <div class="el-image d-block w-100" style="height: 64px;"><div class="el-image__error"><i class="el-icon-picture-outline" style="font-size: 16px;"></i></div></div>
                                <!-- <div class="el-image d-block w-100" style="width: 530px; height: 221px;"><div class="el-image__error">{{ $t('FAILED LOAD IMAGE') }}</div></div> -->
                              </div>
                            </el-image>
                          </router-link>
                          <!-- <a class="d-block mr-2" href="shop-single-v1.html"><img width="64" src="img/shop/cart/widget/01.jpg" alt="Product"/> -->
                          <!-- </a> -->
                          <div class="media-body">
                            <h6 class="widget-product-title">
                              <router-link :to="{ name: 'ProductView', params: { productId: product['product_id']  }}">
                                {{ product['name'] }}
                              </router-link>
                            </h6>
                            <div class="widget-product-meta">
                              <renderPrice :product="product" :withQuantity="true" :withMeasure="true"></renderPrice>
                              <!-- <span class="text-accent mr-2"><span v-if="!product['without_price']">{{ priceFull(product['price']) }}.<small>{{ priceDivision(product['price']) }}</small> ₴.</span></span><span class="text-muted">x {{ product['quantity'] }}</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                    <div class="font-size-sm mr-2 py-2">
                      <span class="text-muted">{{ $t('Subtotal') }}:</span>
                      <span class="text-accent font-size-base ml-1">{{ priceFull(cartSubTotal) }}.<small>{{ priceDivision(cartSubTotal) }}</small> ₴</span>
                    </div>
                    <router-link :to="{ name: 'ShopCart'}" class="btn btn-outline-secondary btn-sm">
                      {{ $t('Expand cart') }}<i class="czi-arrow-right ml-1 mr-n1"></i>
                    </router-link>
                  </div>
                  <router-link :to="{name: 'CheckoutDetails'}" class="btn btn-primary btn-sm btn-block">
                    <i class="czi-card mr-2 font-size-base align-middle"></i>{{ $t('Checkout') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ 'show': toggleMenu }" class="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
        <div class="container">
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <!-- Search-->
            <div class="input-group-overlay d-lg-none my-3">
              <autocomplete
                style="width: 100% !important; background-color: white !important;"
                ref="autocompleteSecond"
                :search="search"
                :placeholder="$t('Search for products')"
                :aria-label="$t('Search for products')"
                :get-result-value="getResultValue"
                @submit="handleSubmit"
              >
              <template
                #default="{
                  rootProps,
                  inputProps,
                  inputListeners,
                  resultListProps,
                  resultListListeners,
                  results,
                  resultProps
                }"
              >
                <div v-bind="rootProps">
                  <input
                    v-bind="inputProps"
                    v-on="inputListeners"
                    :class="[
                      'autocomplete-input',
                      { 'autocomplete-input-no-results': noResults },
                      { 'autocomplete-input-focused': focused }
                    ]"
                  />
                  <!-- @focus="handleFocus"
                    @blur="handleBlur" -->
                  <ul
                    v-if="noResults"
                    class="autocomplete-result-list"
                    style="position: absolute; z-index: 1; width: 100%; top: 100%;"
                  >
                    <li class="autocomplete-result">
                      <i class="navbar-tool-icon czi-menu"></i> No results found
                    </li>
                  </ul>
                  <ul v-bind="resultListProps" v-on="resultListListeners">
                    <li
                      v-for="(result, index) in results"
                      :key="resultProps[index].id"
                      v-bind="resultProps[index]"
                      style="white-space: nowrap !important;"
                    >
                      <template v-if="result.catalog">
                        <i class="navbar-tool-icon czi-list" style="font-size: 1rem;"></i>
                      </template>
                      <template v-else>
                        <i class="navbar-tool-icon czi-search"></i>
                      </template>
                        {{ $i18n.t('Found in category') }}: {{ result['name'] }}
                    </li>
                  </ul>
                </div>
              </template>
              </autocomplete>
            </div>
            <!-- Departments menu-->
            <ul class="navbar-nav mega-nav pr-lg-2 mr-lg-2">
              <li class="nav-item dropdown"><a class="nav-link dropdown-toggle pl-0" href="#" data-toggle="dropdown"><i class="czi-menu align-middle mt-n1 mr-2"></i>{{ $t('Catalogs') }}</a>
                <ul class="dropdown-menu">
                  <li v-for="(catalog, index) in catalogsList" :key="index" class="dropdown mega-dropdown">
                    <a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">{{ catalog['name'] }}</a>
                    <div class="dropdown-menu p-0">
                      <div class="d-flex flex-wrap flex-md-nowrap px-2">
                        <div class="mega-dropdown-column py-4 px-3">
                          <div class="widget widget-links">
                            <h6 class="font-size-base mb-3">{{ $t('Categories') }}</h6>
                            <ul class="widget-list">
                              <li v-for="(category, index) in catalog['categories'].slice(0, 15)" :key="index" class="widget-list-item pb-1">
                                <router-link :to="{ name: 'ShopMain', params: { catalogHumanUrl: catalog['human_url'], categoryId:  'c' + category['id'] } }" class="widget-list-link">
                                  {{ category['name'] }}
                                </router-link>
                              </li>
                              <li v-if="catalog['categories'].length === 0 || catalog['categories'].length <= 15" class="widget-list-item pb-1">
                                <router-link :to="{ name: 'ShopMain', params: { catalogHumanUrl: catalog['human_url'], categoryId:  'c' + 0 } }" class="widget-list-link">
                                  {{ $t('View all') }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div v-if="catalog['categories'].slice(15, catalog['categories'].length).length" class="mega-dropdown-column py-4 px-3">
                          <div class="widget widget-links">
                            <ul class="widget-list">
                              <li v-for="(category, index) in catalog['categories'].slice(15, 29)" :key="index" class="widget-list-item pb-1">
                                <router-link :to="{ name: 'ShopMain', params: { catalogHumanUrl: catalog['human_url'], categoryId:  'c' + category['id'] } }" class="widget-list-link">
                                  {{ category['name'] }}
                                </router-link>
                              </li>
                              <li class="widget-list-item pb-1">
                                <router-link :to="{ name: 'ShopMain', params: { catalogHumanUrl: catalog['human_url'], categoryId:  'c' + 0 } }" class="widget-list-link">
                                  {{ $t('View all') }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <!-- Primary menu-->
            <ul class="navbar-nav" id="header_anchor">
              <li class="nav-item" v-bind:class="{ 'active': $route.name === 'AboutUs' }">
                <router-link :to="{ name: 'AboutUs' }" class="nav-link">
                  {{ $t('About Us') }}
                </router-link>
              </li>
              <li class="nav-item" v-bind:class="{ 'active': $route.name === 'Services' }">
                <router-link :to="{ name: 'Services' }" class="nav-link">
                  {{ $t('Service') }}
                </router-link>
              </li>
              <li class="nav-item" v-bind:class="{ 'active': $route.name === 'Promotions' }">
                <router-link :to="{ name: 'Promotions' }" class="nav-link">
                  {{ $t('Promotions') }}
                </router-link>
              </li>
              <li class="nav-item" v-bind:class="{ 'active': $route.name === 'PaymentAndDelivery' }">
                <router-link :to="{ name: 'PaymentAndDelivery' }" class="nav-link">
                  {{ $t('payment_and_delivery') }}
                </router-link>
              </li>
              <li class="nav-item" v-bind:class="{ 'active': $route.name === 'Contacts' }">
                <router-link :to="{ name: 'Contacts' }" class="nav-link">
                  {{ $t('Contacts') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
// import VueTinySlider from 'vue-tiny-slider'
import { mapGetters } from 'vuex' // mapState
import $ from 'jquery'
import renderPrice from '@/components/Product/renderPrice.vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
// import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
  name: 'Header',
  components: {
    // 'tiny-slider': VueTinySlider,
    Autocomplete,
    renderPrice
  },
  data () {
    return {
        noResults: false,
        focused: false,
        isSticky: false,
        toggleMenu: false,
        language: 'ru',
        showSignIn: false,
        fullPrice: 0
        // catalogs: [],
    }
  },
  beforeMount () {
      window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      cartProducts: 'cart/cartProducts',
      cartSubTotal: 'cart/cartSubTotal',
      catalogsList: 'catalog/catalogs',
      wishlistsSubTotal: 'wishlist/wishlistsSubTotal',
      settings: 'settings'
    }),
    settingsPhones () {
      return this.settings && this.settings.length && this.settings[0].phone ? this.settings[0].phone : []
    },
    settingsWorkTimes () {
      return this.settings && this.settings.length && this.settings[0].work_time ? this.settings[0].work_time : []
    }
  },
  created () {
    setTimeout(() => {
      this.dropDownMenu()
    }, 1500);
  },
  methods: {
    search(input) {
      return new Promise(resolve => {
        if (input.length < 1) {
          return resolve([])
        }
        // console.log('dispatch')
        this.$store.dispatch('product/search', input ).then((response) => {
          // this.noResults =
          this.focused = true
          resolve(response)
        }).catch(() => {
        })
      })
    },
    // We want to display the title
    getResultValue(result) {
      return result.name['ru']
    },
    // Open the selected article in
    // a new window
    handleSubmit(result) {
      // let prodId = result['id']
      this.$refs.autocomplete.value = ''
      this.$refs.autocompleteSecond.value = ''
      if (result.catalog) {
        this.$router.push({ name: 'ShopMain', params: { catalogHumanUrl: result['catalog']['human_url'], categoryId:  'c' + result['id'] } })
      } else {
        this.$router.push({ name: 'ProductView', params: { productId: result['id'] }})
      }
      // console.log(result)
    },
    dropDownMenu () {
      const buttons = document.querySelectorAll(".dropdown-menu [data-toggle='dropdown']")
      buttons.forEach((button) => {
        button.addEventListener('click', (event) => {
          event.preventDefault(), event.stopPropagation(), $(event.currentTarget).siblings().toggleClass("show"), $(event.currentTarget).next().hasClass("show") || $(event.currentTarget).parents(".dropdown-menu").first().find(".show").removeClass("show"), $(event.currentTarget).parents("li.nav-item.dropdown.show").on("hidden.bs.dropdown", function() {
              $(".dropdown-submenu .show").removeClass("show")
          })
        })
      })
    },
    removeProduct (product) {
      this.$store.dispatch('cart/removeItem', product.product_id).then(() => {
      }).catch(() => {})
    },
    logOut () {
        this.$store.dispatch('auth/logOut')
    },
    handleScroll () {
      var t = document.querySelector(".navbar-sticky")
      if (null != t) {
        var r = t.offsetHeight
        // console.log(r)
        if (400 < window.scrollY) {
          (t.classList.contains("navbar-transparent") || (document.body.style.paddingTop = r + "px"), this.isSticky = true)
        } else {
          (document.body.style.paddingTop = "", this.isSticky = false)
        }
        // if (window.scrollY > 400) {
        //   this.isSticky = true;
        //   document.body.style.paddingTop = r + "px"
        // } else {
        //   document.body.style.paddingTop = ""
        // }
      }
    },
    toggleMenuBtn () {
      this.toggleMenu = !this.toggleMenu
      // this.$els.classList.contains('navbar-stuck-toggler')
      // console.log('toggle menu')
    },
    openSignInModal: function () {
      this.$parent.$emit('openSignInModal')
    }
  }
}
</script>
<style>

.autocomplete-input {
  background-color: white;
}

.autocomplete-input:focus, .autocomplete-input[aria-expanded=true] {
    border-color: rgba(175, 47, 46, 0.3);
    background-color: #fff;
    outline: none;
    box-shadow: 0 2px 2px rgba(0,0,0,.16);
}

.autocomplete-result-list {
  border-color: rgba(175, 47, 46, 0.3);
  z-index: 999 !important;
}

.autocomplete-input {
	border: 1px solid #eee;
	border-radius: 8px;
	width: 100%;
	padding: 12px 12px 12px 48px;
	box-sizing: border-box;
	position: relative;
	font-size: 16px;
	line-height: 1.5;
	flex: 1;
	background-color: #eee;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
	background-repeat: no-repeat;
	background-position: 12px
}

.autocomplete-input:focus,
.autocomplete-input[aria-expanded=true] {
	border-color: rgba(0, 0, 0, .12);
	background-color: #fff;
	outline: none;
	box-shadow: 0 2px 2px rgba(0, 0, 0, .16)
}

[data-position=below] .autocomplete-input[aria-expanded=true] {
	border-bottom-color: transparent;
	border-radius: 8px 8px 0 0
}

[data-position=above] .autocomplete-input[aria-expanded=true] {
	border-top-color: transparent;
	border-radius: 0 0 8px 8px;
	z-index: 2
}

.autocomplete[data-loading=true]:after {
	content: "";
	border: 3px solid rgba(0, 0, 0, .12);
	border-right-color: rgba(0, 0, 0, .48);
	border-radius: 100%;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);
	animation: rotate 1s linear infinite
}

.autocomplete-result-list {
	margin: 0;
	border: 1px solid rgba(0, 0, 0, .12);
	padding: 0;
	box-sizing: border-box;
	max-height: 296px;
	overflow-y: auto;
	background: #fff;
	list-style: none;
	box-shadow: 0 2px 2px rgba(0, 0, 0, .16)
}

[data-position=below] .autocomplete-result-list {
	margin-top: -1px;
	border-top-color: transparent;
	border-radius: 0 0 8px 8px;
	padding-bottom: 8px
}

[data-position=above] .autocomplete-result-list {
	margin-bottom: -1px;
	border-bottom-color: transparent;
	border-radius: 8px 8px 0 0;
	padding-top: 8px
}

.autocomplete-result {
	cursor: default;
  /* padding: 12px 12px 12px 48px; */
  padding: 12px 12px 12px 12px;
	/* background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+"); */
	background-repeat: no-repeat;
	background-position: 12px
}

.autocomplete-result:hover,
.autocomplete-result[aria-selected=true] {
	background-color: rgba(0, 0, 0, .06)
}

@keyframes rotate {
	0% {
		transform: translateY(-50%) rotate(0deg)
	}
	to {
		transform: translateY(-50%) rotate(359deg)
	}
}

</style>