<template>
  <div>
    <div class="modal fade"
        :id="modalId"
        :class="modalClass"
        tabindex="-1"
        role="dialog"
        aria-labelledby=""
        aria-hidden="true">
        <div class="modal-dialog"
             :class="modalDialogClass"
             role="document">
          <div class="modal-content">
            <!-- header -->
            <div class="modal-header">
              <slot name="header"></slot>
            </div>
            <!-- end header -->
            <!-- body -->
            <div class="modal-body" :class="modalBodyClass">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
            <!-- end body -->
          </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalComponent',
  props: {
    modalId: String,
    modalClass: String,
    modalDialogClass: String,
    modalBodyClass: String
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>

</style>
