// import axios from 'axios'
import Vue from 'vue';
import { HTTP } from '@/api/http_utils'
// import { ADD_TO_CART } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        // cartProducts: JSON.parse(localStorage.getItem('vuex.cartProducts')) ?? [],
        cartProducts: [],
        cartSubTotal: 0,
        test: 0,
        getCartProductsCount: 0
    },
    getters: {
        testCount: state  => state.test ? state.test : 0,
        // getCartProductsCount: state => (state.all) ? state.all.length : 0,
        cartProductsCount: state => (state.cartProducts) ? state.cartProducts.length : 0,
        cartProducts: state => state.cartProducts,
        cartSubTotal: state => state.cartProducts.reduce((subtotal, item) => subtotal + (item.quantity <= 0 ? 1 : item.quantity * (item.without_price ? 0 : (item.discount ? item.discount.price : item.price) )), 0)
    },
    mutations: {
        ADD_TO_CART (state, data) {
            state.cartProducts = data
        }
    },
    actions: {
        add ({ commit }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/carts', data).then((response) => {
                    commit('ADD_TO_CART', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateQuantity ({ commit }, [productId, quantity]) {
            return new Promise((resolve, reject) => {
                HTTP.patch(`/carts/${productId}`, { quantity: quantity }).then((response) => {
                    commit('ADD_TO_CART', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeItem ({ commit }, productId) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/carts/${productId}`).then((response) => {
                    commit('ADD_TO_CART', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        clear ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/carts-clear`).then((response) => {
                    commit('ADD_TO_CART', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        get ({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/carts').then((response) => {
                    commit('ADD_TO_CART', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // getList ({ commit }) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.get('/wishlists').then((response) => {
        //             commit('SET_WISHLISTS', response)
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // create ({ commit }, data) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.post('/wishlists/', data).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
        // delete ({ commit }, Id) {
        //     return new Promise((resolve, reject) => {
        //         HTTP.delete('/wishlists/' + Id).then((response) => {
        //             resolve(response)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // }
    },
  };